import USER_QUERY from '../USER_QUERY'

const addTeam = ({ store, data: { newTeam } }) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        teams: [
            newTeam,
          ...data.teams,
        ],
      },
    },
  })
}

export default addTeam
