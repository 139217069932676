import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Picker from 'emoji-picker-react'

// const useStyles = makeStyles(theme => ({
//   typography: {
//     padding: theme.spacing(2),
//   },
// }))

export default function EmojiPicker(props) {
  const { children, onSelect } = props
  // const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <React.Fragment>
      {children({
        ...props,
        id,
        handleClick,
      })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Picker
          onEmojiClick={(event, emojiObject) => {
            onSelect(emojiObject)
          }}
          pickerStyle={{ width: '100%' }}
        />
      </Popover>
    </React.Fragment>
  )
}
