import PubSub from 'pubsub-js'
import USER_QUERY from '../USER_QUERY'

const updateUserMeta = ({ store, data: { itemId } }) => {
  const queryProps = { query: USER_QUERY }
  const data = store.readQuery(queryProps)
  const newData = {
    ...data,
    userMeta: {
      ...data.userMeta,
      items: data.userMeta.items.filter(item => item.id !== itemId),
    },
  }
  // console.log('NEW_ITEM', data, newData)
  store.writeQuery({
    ...queryProps,
    data: newData,
  })
}

const updateTeam = ({ store, data: { newItem: updatedItem, teamId } }) => {
  if (!updatedItem) {
    return
  }
  PubSub.publish('itemHasBeenUpdated', { updatedItem })
}

const handOverItem = ({ store, data }) => {
  console.log('storeAPI handOverItem', data)
  updateUserMeta({ store, data })
  try {
    updateTeam({ store, data })
  } catch (e) {
    console.log(e)
  }
}

export default handOverItem
