import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import Grid from '@material-ui/core/Grid'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import Copyright from '../Copyright'
import { Redirect } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import auth from '../../libs/auth'
import useForm from '../../libs/hooks/useForm'
import getErrorMessage from '../../libs/getErrorMessage'
import { useIntl } from 'react-intl'
import messages from './messages'

const REGISTRATION = gql`
  mutation Registration($email: String!, $password: String!) {
    register(email: $email, password: $password)
  }
`

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function Registration(props) {
  const [register, { data, loading, error }] = useMutation(REGISTRATION)
  const { formatMessage } = useIntl()
  const { form, fieldProps /* , setField */, validate } = useForm({
    email: {
      value: '',
      error: '',
      required: true,
      validation: [
        {
          regExp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'The email is not valid',
        },
      ],
    },
    password: {
      value: '',
      error: '',
      required: true,
    },
  })

  const [open, setOpen] = React.useState(false)
  const [showDialogButtons, setShowDialogButtons] = React.useState(false)
  const [showAgreementError, setShowAgreementError] = React.useState(false)
  const [agreement, setAgreement] = React.useState(false)

  React.useEffect(() => {
    if (data && props.login) {
      props.login({
        tokenId: data.register.token,
        user: data.register.user,
      })
    }
  }, [props, data])
  const classes = useStyles()

  if (props.auth.user.id) {
    return <Redirect to="/dashboard" />
  }

  const formError = (() => {
    if (error) {
      return getErrorMessage(error)
    }
    return
  })()

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {formatMessage({ ...messages.title })}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={async e => {
            e.preventDefault()
            const { hasErrors } = validate()
            if (!hasErrors && !agreement) {
              setShowAgreementError(true)
            }
            if (!hasErrors && agreement) {
              try {
                await register({
                  variables: {
                    email: form.email.value,
                    password: form.password.value,
                  },
                })
              } catch (err) {
                console.log('registration error', err)
              }
            }
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={formatMessage({ ...messages.emailLabel })}
            name="email"
            autoComplete="email"
            autoFocus
            {...fieldProps({
              field: 'email',
              customError: formError === 'EMAIL_IS_USED' && formatMessage({ ...messages.EMAIL_IS_USED }),
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={formatMessage({ ...messages.passwordLabel })}
            type="password"
            id="password"
            autoComplete="current-password"
            {...fieldProps({
              field: 'password',
            })}
          />
          <Button
            onClick={() => setOpen(true)}
            className={classes.button}
            size="small"
            fullWidth
            startIcon={agreement ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
          >
            {formatMessage({ ...messages.termConditionsLabel })}
          </Button>
          {showAgreementError && (
            <Typography color="error" variant="caption" display="block" align="center" gutterBottom>
              {formatMessage({ ...messages.termConditionsError })}
            </Typography>
          )}
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false)
              setShowDialogButtons(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{formatMessage({ ...messages.termConditionsTitle })}</DialogTitle>
            <DialogContent
              onScroll={event => {
                if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
                  setShowDialogButtons(true)
                }
              }}
            >
              <DialogContentText id="alert-dialog-description">
                {formatMessage(
                  { ...messages.termConditionsText },
                  {
                    p: (...text) => <p>{text}</p>,
                  },
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Typography variant="body2" color="textSecondary" align="center" style={{ width: '100%' }}>
                <b>{formatMessage({ ...messages.termConditionsHelpText })}</b>
              </Typography>
            </DialogActions>
            <DialogActions>
              <Button
                onClick={() => {
                  setAgreement(false)
                  setOpen(false)
                  setShowDialogButtons(false)
                }}
                color="primary"
              >
                {formatMessage({ ...messages.termConditionsTextDisagree })}
              </Button>
              <Button
                disabled={!showDialogButtons}
                onClick={() => {
                  setAgreement(true)
                  setOpen(false)
                  setShowAgreementError(false)
                }}
                color="primary"
                autoFocus
              >
                {formatMessage({ ...messages.termConditionsTextAgree })}
              </Button>
            </DialogActions>
          </Dialog>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {formatMessage({ ...messages.signupButton })}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#/forget-password" variant="body2">
                {formatMessage({ ...messages.forgotPassword })}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#/login" variant="body2">
                {formatMessage({ ...messages.login })}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: ({ tokenId, user }) => {
    auth.login({ tokenId, user })
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Registration)
