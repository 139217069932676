import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import client from '../../libs/client'
import USER_QUERY from '../../libs/gql/USER_QUERY'
import storeAPI from '../../libs/gql/storeAPI'
import getQueryDates from '../../libs/gql/getQueryDates'
import useInactivePage from '../../libs/hooks/useInactivePage'
import Loader from '../Loader'
// import p2p from '../../libs/p2p'
import pusher, { getChannel } from '../../libs/pusher'
import pusherPushNotifications from '../../libs/beams'

const queryDates = getQueryDates('USER_QUERY')

const chatHandler = ({ data, userId, setFeedback }) => {
  if (userId !== data.newMessage.u.id) {
    console.log('ws-listener', 'chat', 'chatChannel', data)
    const { channelData = {}, action } = data
    storeAPI.getNewMessage({ store: client, data: { ...data, doUpdateUser: true, doUpdateChat: true } })
    if (['itemHandoverAssignment', 'sendItemHandoverRequest'].includes(action)) {
      console.log('ws-listener', action, channelData.item)
      storeAPI.editItem({
        store: client,
        data: {
          updatedItem: channelData.item,
          itemId: channelData.item.id,
          teamId: channelData.item.teamId,
        },
      })
    }
    if (action === 'acceptedItemHandoverRequest') {
      console.log('ws-listener', action, channelData.item)
      if (channelData.item.borrower.id === userId) {
        storeAPI.getItem({
          store: client,
          data: {
            newItem: channelData.item,
            itemId: channelData.item.id,
            teamId: channelData.item.teamId,
          },
        })
        storeAPI.editItem({
          store: client,
          data: {
            updatedItem: channelData.item,
            itemId: channelData.item.id,
            teamId: channelData.item.teamId,
          },
        })
      } else {
        storeAPI.removeItem({
          store: client,
          data: {
            updatedItem: {
              ...channelData.item,
            },
            itemId: channelData.item.id,
            teamId: channelData.item.teamId,
          },
        })
      }
    }
    setFeedback({
      open: true,
      status: 'success',
      text: `you got a new message`,
    })
  }
}

export default function PrivateRoute(props) {
  const { component: Component, ...rest } = props
  const {
    location: { pathname: from },
  } = rest

  const redirectPropTo = {
    pathname: '/login',
    state: { from },
  }
  const lastQuery = React.useRef()
  const [pusherConnectionState, setPusherConnectionState] = React.useState()
  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    onCompleted: () => {
      lastQuery.current = queryDates.update()
    },
  })
  const unsubscribeDuration = 30 * 60 * 1000
  // const unsubscribeDuration = 10 * 1000
  const { isActive } = useInactivePage(unsubscribeDuration)
  const doRefetch = isActive && pusher.connection.state !== 'connected'
  const userId = rest.auth?.user?.id

  React.useEffect(() => {
    pusher.connection.bind('connecting', function() {
      console.log('sub connecting')
      setPusherConnectionState(pusher.connection.state)
    })
    pusher.connection.bind('connected', function() {
      console.log('sub connected')
      setPusherConnectionState(pusher.connection.state)
    })
    pusher.connection.bind('disconnected', function() {
      console.log('sub disconnected')
      setPusherConnectionState(pusher.connection.state)
    })
    // let peer
    // if (userId) {
    //   peer = p2p(`takina_user_${userId}`)
    // }
    return () => {
      console.log('unsubscribe ws connection')
      pusher.connection.unbind('connecting')
      pusher.connection.unbind('connected')
      pusher.connection.unbind('disconnected')
      pusher.disconnect()
      // if (peer && peer.disconnect) {
      //   peer.disconnect()
      // }
    }
  }, [userId])
  const teams = data?.userMeta?.teams

  const chatChannels = (() => {
    const { chatChannels = [] } = data?.userMeta || {}
    // const itemsChatChannels = items.map(item => `chat_item_${item.id}`)
    const itemsChatChannels = chatChannels.map(chatChannel => `chat_item_${chatChannel.item.id}`)
    // chatChannels.forEach(chatChannel => {
    //   if (!itemsChatChannels.includes(chatChannel.PK)) {
    //     itemsChatChannels.push(chatChannel.PK)
    //   }
    // })
    return itemsChatChannels
  })()
  const doSubEvents = teams && userId && pusherConnectionState === 'connected'
  console.log(
    'sub',
    'doSubEvents',
    doSubEvents,
    'teams',
    teams,
    'userId',
    userId,
    'pusherConnectionState',
    pusherConnectionState,
  )
  const { setFeedback } = rest

  const events = [
    {
      name: 'getItem',
      handler: data => {
        storeAPI.getItem({ store: client, data })
        setFeedback({
          open: true,
          status: 'success',
          text: `You got ${data.newItem.name}`,
        })
      },
    },
    {
      name: 'handOverItem',
      handler: data => {
        storeAPI.handOverItem({ store: client, data })
        setFeedback({
          open: true,
          status: 'success',
          text: `You hand over an item`,
        })
      },
    },
    {
      name: 'chat',
      handler: data => {
        chatHandler({ data, userId, setFeedback })
      },
    },
  ]
  React.useEffect(() => {
    if (!isActive && pusher.connection.state === 'connected') {
      pusher.disconnect()
    } else if (isActive && pusher.connection.state !== 'connected') {
      if (pusher.connection.state !== 'connecting') {
        pusher.connect()
        refetch()
        queryDates.update()
      }
    }
    console.log('sub', doSubEvents)
    if (doSubEvents) {
      console.log('ws subscribe events')
      // unsubscribeChannels()
      //
      const beams = pusherPushNotifications(userId)
      const beamsClient = beams.getInstance()

      teams.forEach(team => {
        const channel = getChannel(`team_${team.id}`)

        channel.bind('itemHasBeenUpdated', function(data) {
          storeAPI.itemHasBeenUpdated({ store: client, data })
        })
        // channel.bind('addItem', function(data) {
        //   console.log('ws', 'addItem', data)
        //   storeAPI.addItem({ store: client, data: { ...data, updateTeam: true, updateUserMeta: false } })
        //   if (userId !== data.borrowerId) {
        //     setFeedback({
        //       open: true,
        //       status: 'success',
        //       text: `${data.newItem.add.nam} added new item`,
        //     })
        //   }
        // })
      })

      chatChannels.forEach(chatChannel => {
        const channel = getChannel(chatChannel)
        channel.bind('chat', function(data) {
          chatHandler({ data, userId, setFeedback })
        })

        if (beamsClient) {
          beamsClient.addDeviceInterest(chatChannel)
        }
      })

      const channel = getChannel(`user_${userId}`)

      events.forEach(event => {
        channel.bind(event.name, data => {
          console.log('ws-listener', event.name, data)
          event.handler(data)
        })
      })
    }
    return () => {
      console.log('ws- unsubscribe ws channels')
      chatChannels.forEach(chatChannel => {
        const channel = getChannel(chatChannel)
        pusher.unsubscribe(channel.name)
      })

      pusher.unsubscribe(`user_${userId}`)
      // pusher.allChannels().forEach(channel => {
      //   channel.unbind('addItem')
      //   channel.unbind('getItem')
      //   channel.unbind('handOverItem')
      //   channel.unbind('itemHasBeenUpdated')
      // })
    }
  }, [events, teams, userId, doSubEvents, isActive, refetch, setFeedback /* , queryDates */, chatChannels])

  if (loading) return <Loader loading />
  if (error && rest.auth.user.id) return `Error! ${error.message}`

  const { userMeta } = data || {}

  return (
    <Route
      {...rest}
      render={props =>
        rest.auth.user.id ? (
          <React.Fragment>
            <Component {...props} doRefetch={doRefetch} userMeta={userMeta} />
          </React.Fragment>
        ) : (
          <Redirect to={redirectPropTo} />
        )
      }
    />
  )
}
