import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));
export default function Loader(props) {
  const {loading/* , error */} = props;
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
