import React from 'react'

export default function useInactivePage(duration) {
  const [documentStatus, setDocumentStatus] = React.useState({
    isActive: true,
    inactiveAt: undefined,
  })

  let timeout

  window.onfocus = function() {
    const inactiveAt = undefined
    const isActive = true

    timeout && clearTimeout(timeout)
    setDocumentStatus({
      isActive,
      inactiveAt,
    })
  }

  window.onblur = function() {
    const inactiveAt = new Date().getTime()
    const isActive = false

    setTimeout(() => {
      setDocumentStatus({
        isActive,
        inactiveAt,
      })
    }, duration)
  }
  // setInterval(() => {
  //   let isActive = !document.hidden
  //   let inactiveAt
  //   if (!isActive && !inactiveAt) {
  //     inactiveAt = new Date().getTime()
  //   }
  //   if (isActive) {
  //     inactiveAt = undefined
  //   }
  //   setDocumentStatus({
  //     isActive,
  //     inactiveAt,
  //   })
  // }, 2000)

  return documentStatus
}
