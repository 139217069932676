const PREFIX = 'APP'
export const APP_UPDATE = `${PREFIX}_UPDATE`
export const APP_UPDATE_CHAT = `${PREFIX}_UPDATE_CHAT`
export const updateApp = (payload = {}) => ({
  type: APP_UPDATE,
  payload,
})
export const updateChat = (payload = {}) => ({
  type: APP_UPDATE_CHAT,
  payload,
})

const INITIAL_STATE = {
  feedback: {
    open: false,
    status: null,
    text: '',
  },
  chat: JSON.parse(localStorage.getItem('takina-chat') || '{}'),
}
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APP_UPDATE:
      return { ...state, ...action.payload }
    case APP_UPDATE_CHAT:
      localStorage.setItem('takina-chat', JSON.stringify(action.payload.chat))
      return { ...state, ...action.payload }
    default:
      return state
  }
}
