import React from 'react'
// import moment from 'moment'
// import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import getAvatarUrl from '../../libs/avatarUrl'

// const useStyles = makeStyles(theme => ({}))

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
  },
  listItem: {
    padding: 20,
  },
}))
export default function TeamUsers(props) {
  const { team } = props
  const { users } = team
  const classes = useStyles()

  return (
    <React.Fragment>
      <Card>
        <List>
          {users.map((user, index) => {
            return (
              <React.Fragment key={`team-user-${user.id}`}>
                <ListItem
                  className={classes.listItem}
                  role={undefined}
                  dense
                  button={false}
                  onClick={() => {
                    // history.push(`/team/${team.id}/item/${teamChatChannel.itemId}/tab/chat`)
                  }}
                >
                  <ListItemText
                    primary={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          alt={user.name}
                          src={getAvatarUrl({ seed: user.id })}
                          style={{ margin: '0 5px 0 0', backgroundColor: '#f0f0f0' }}
                        />
                        {user.name}
                      </div>
                    }
                  />
                </ListItem>
                {users.length - 1 > index ? <Divider /> : null}
              </React.Fragment>
            )
          })}
        </List>
      </Card>
    </React.Fragment>
  )
}
