// import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import { updateApp } from '../../libs/redux/reducers/app'
import { useIntl } from 'react-intl'
import messages from './messages'

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($teamName: String!, $teamId: String!, $invited: JSON!) {
    acceptInvitation(teamName: $teamName, teamId: $teamId, invited: $invited)
  }
`

function AcceptInvitation(props) {
  const { setFeedback, children, update } = props
  const { formatMessage } = useIntl()

  const [acceptInvitation, acceptInvitationResponse] = useMutation(ACCEPT_INVITATION, {
    update,
  })

  return children({
    ...props,
    acceptInvitation: async variables => {
      try {
        await acceptInvitation({ variables })
        setFeedback({
          open: true,
          status: 'success',
          text: formatMessage({ ...messages.success }),
        })
      } catch (e) {
        console.log('acceptInvitation', e)
        setFeedback({
          open: true,
          status: 'error',
          text: formatMessage({ ...messages.error }),
        })
      }
    },
    acceptInvitationResponse,
  })
}

const mapStateToProps = null

const mapDispatchToProps = dispatch => ({
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptInvitation)
