const updateDate = namespace => () => {
  const date = new Date().toString()
  const NEW_QUERY_DATE = localStorage.getItem(`NEW_${namespace}`)
  if (!NEW_QUERY_DATE) {
    localStorage.setItem(`NEW_${namespace}`, date)
  } else {
    localStorage.setItem(`NEW_${namespace}`, date)
    localStorage.setItem(namespace, NEW_QUERY_DATE)
  }
}

const getDate = namespace => () => {
  return localStorage.getItem(namespace)
}

export default function getQueryDates(namespace) {
  return {
    update: updateDate(namespace),
    get: getDate(namespace),
  }
}
