import { defineMessages } from 'react-intl'

export default defineMessages({
  scanDialogTitle: 'Scan',
  handoverDialogTitle: 'Handover',
  handoverDialogContent: `<b>{borrowerName}</b>, from <b>{teamName}</b>, wants to hand <b>{itemName}</b> over to you`,
  handoverDialogDoYouAccept: `Do you accept?`,
  handoverDialogAcceptButton: `Accept`,
  dialogCancelButton: `Cancel`,
})
