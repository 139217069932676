import moment from 'moment'
import reduxStore from '../redux/store'
import * as authActions from '../redux/reducers/auth'
import store from '../store'
import client from '../client'
// import 'moment/locale/en';
import 'moment/locale/el'
moment.locale('en-gb')

function login({ tokenId, user }) {
  client.resetStore()
  store.set('tokenId', tokenId)
  store.set('user', JSON.stringify(user))

  // const index = Math.floor(Math.random() * Math.floor(2));
  // const langs = ['en', 'el'];
  moment.locale('en-gb')

  reduxStore.dispatch(
    authActions.login({
      user,
    }),
  )
}
function getUser() {
  const user = store.get('user')
  return JSON.parse(user)
}
function getTokenId() {
  return store.get('tokenId')
}
function logout() {
  store.set('tokenId', '')
  store.remove('tokenId')
  store.set('user', '{ "wasLoggedOut": true }')
  reduxStore.dispatch(authActions.logout())
}
export default {
  login,
  logout,
  getTokenId,
  getUser,
}
