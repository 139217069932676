import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ItemListItem from '../ItemListItem'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}))

export default function ItemsList(props) {
  const { userMeta, items = [], team } = props
  const classes = useStyles()

  const { items: userItems = [] } = userMeta
  const userItemsIds = userItems.map(item => item.id)
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {items.map(item => {
          const isMine = userItemsIds.includes(item.id)
          return (
            <Grid key={`item-${item.id}`} item xs={12} sm={4}>
              <ItemListItem userMeta={userMeta} data={item} team={team} isMine={isMine} />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
