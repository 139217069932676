import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import TeamForm from '../TeamForm'

const ADD_TEAM = gql`
  mutation AddTeam($name: String!) {
    addTeam(name: $name)
  }
`

export default function AddTeam(props) {
  const { update, children } = props
  const [open, setOpen] = React.useState(false)

  const [addTeam, { loading, error /* , data */ }] = useMutation(ADD_TEAM, {
    update,
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      <TeamForm formType="ADD" onSubmit={addTeam} open={open} setOpen={setOpen} />
    </React.Fragment>
  )
}
/*
      <Button
        color="secondary"
        onClick={() => {
          setOpen(true)
        }}
      >
        {formatMessage({ ...messages.button })}
      </Button>
      */
