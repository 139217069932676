/* eslint-disable */
import React from 'react'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

// import { makeStyles } from '@material-ui/core/styles'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import Logs, { LogText } from '../Logs'
import Loader from '../Loader'

const ITEM_LOGS = gql`
  query ItemLogs($id: String!) {
    itemLogs(id: $id)
  }
`

// const useStyles = makeStyles(theme => ({
//   paper: {
//     padding: '6px 16px',
//   },
//   secondaryTail: {
//     backgroundColor: theme.palette.secondary.main,
//   },
// }))

export default function ItemLogs(props) {
  const { item } = props
  // const classes = useStyles()
  const { loading, error, data } = useQuery(ITEM_LOGS, {
    variables: {
      id: item.id,
    },
  })

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`

  console.log('ITEM', 'LOGS', data)
  const { itemLogs: logs = [] } = data

  const LOG_TYPE = {
    ITEM_ADDED: {
      title: 'Added',
      date: log => moment(log.add.at).format('LLLL'),
      text: log => <LogText>by {log.add.nam}</LogText>,
      icon: () => <SyncAltIcon />,
    },
    ITEM_REQUEST_ACCEPTED: {
      title: 'Item request accepted',
      date: log => moment(log.acc.at).format('LLLL'),
      text: log => (
        <LogText>
          {log.acc.nam} accepted to had over {log.item.nam} the to {log.req.nam}
        </LogText>
      ),
      icon: () => <SyncAltIcon />,
    },
    ITEM_REQUEST: {
      title: 'Item Request',
      date: log => moment(log.req.at).format('LLLL'),
      text: log => (
        <LogText>
          <b>{log.req.nam}</b> requested <b>{log.item.nam}</b> from <b>{log.bor.nam}</b>
        </LogText>
      ),
      icon: () => <SyncAltIcon />,
    },
  }
  return <Logs logs={logs} LOG_TYPE={LOG_TYPE} />
}
