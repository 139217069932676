import React from 'react'
import moment from 'moment'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
// import ImageIcon from '@material-ui/icons/Image'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
// import { green, red, grey } from '@material-ui/core/colors'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

export default function ItemInfo(props) {
  const { team, item, isMine, showDesc = false } = props
  return (
    <React.Fragment>
      <Typography gutterBottom variant="body2" style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
        {item.ownership === 'COLLECTIVE' ? <SupervisedUserCircleIcon color="action" /> : null}
        {item.ownership === 'PRIVATE' ? <AccountCircleIcon color="action" /> : null}

        <span style={{ marginLeft: 5 }}>
          {item.ownership === 'COLLECTIVE' ? team.name : null}
          {item.ownership === 'PRIVATE' ? item.owner.name : null}
        </span>
      </Typography>

      <Divider />

      <Typography gutterBottom variant="body2" style={{ marginTop: 10, display: 'flex' }}>
        <AssignmentIndIcon color={isMine ? 'primary' : 'action'} />
        <span style={{ marginLeft: 5 }}>
          {item.borrower.name}
          <Typography variant="caption" display="block" gutterBottom>
            <i>{moment(item.borrower.at).format('LLLL')}</i>
          </Typography>
        </span>
      </Typography>
      {showDesc ? (
        <React.Fragment>
          <Divider />

          <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: 10 }}>
            {item.description}
          </Typography>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}
