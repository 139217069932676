import React from 'react'
import moment from 'moment'
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import { updateApp } from '../../libs/redux/reducers/app'
import Loader from '../Loader'
import storeAPI from '../../libs/gql/storeAPI'
import { useIntl } from 'react-intl'
import messages from './messages'

const ACCEPT_ITEM_HANDOVER_REQUEST = gql`
  mutation AcceptItemHandoverRequest($itemId: String!) {
    acceptItemHandoverRequest(itemId: $itemId)
  }
`

// const EXPIRED_TIME_IN_MUNITES = 60 * 12
const EXPIRED_TIME_IN_MUNITES = 1
export const getExpiredMessage = function(formatMessage) {
  return formatMessage({ ...messages.expired })
}

export function isValidSentHandoverRequestAt({ sentHandoverRequestAt, now }) {
  const duration = moment.duration(moment(now).diff(moment(sentHandoverRequestAt)))
  // const hours = duration.asHours()
  const minutes = duration.asMinutes()

  console.log('duration', minutes, moment(sentHandoverRequestAt).format('HH:mm'), moment(now).format('HH:mm'))
  if (minutes > EXPIRED_TIME_IN_MUNITES) {
    return false
  }
  return true
}

function AcceptItemHandoverRequest(props) {
  const {
    auth: { user },
    setFeedback,
    children,
    item,
    inChat = false,
  } = props

  const { formatMessage } = useIntl()
  const [acceptItemHandoverRequest, acceptItemHandoverRequestResponse] = useMutation(ACCEPT_ITEM_HANDOVER_REQUEST, {
    update: (store, { data: { acceptItemHandoverRequest } }) => {
      const { channelData = {} } = acceptItemHandoverRequest
      const item = {
        ...acceptItemHandoverRequest.channelData.item,
        itemHandoverAssignment: null,
      }
      storeAPI.getItem({
        store,
        data: {
          newItem: item,
          channelData,
          teamId: item.teamId,
        },
      })

      if (inChat) {
        storeAPI.getNewMessage({
          store,
          data: {
            ...acceptItemHandoverRequest,
            item,
            doUpdateUser: true,
            doUpdateChat: true,
          },
        })
      }

      storeAPI.editItem({
        store,
        data: {
          updatedItem: item,
          itemId: item.id,
          teamId: item.teamId,
        },
      })
    },
  })

  const [now, setNow] = React.useState(new Date())
  React.useEffect(() => {
    setInterval(() => {
      setNow(new Date())
    }, 1000 * 60)
  }, [setNow])

  if (!item?.itemHandoverAssignment?.sentHandoverRequestAt) {
    return null
  }

  if (user.id === item?.borrower?.id || user.id !== item.itemHandoverAssignment.receiver.id) {
    return null
  }

  const isValidTime = isValidSentHandoverRequestAt({
    sentHandoverRequestAt: item.itemHandoverAssignment.sentHandoverRequestAt,
    now,
  })

  if (acceptItemHandoverRequestResponse.loading) return <Loader loading />

  if (!isValidTime) {
    return getExpiredMessage(formatMessage)
  }

  return children({
    ...props,
    acceptItemHandoverRequest: async variables => {
      try {
        await acceptItemHandoverRequest({ variables })
        setFeedback({
          open: true,
          status: 'success',
          text: formatMessage({ ...messages.success }),
        })
      } catch (e) {
        console.log('acceptItemHandoverRequest', e)
        setFeedback({
          open: true,
          status: 'error',
          text: formatMessage({ ...messages.error }),
        })
      }
    },
    acceptItemHandoverRequestResponse,
  })
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptItemHandoverRequest)
