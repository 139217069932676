import { defineMessages } from 'react-intl'

export default defineMessages({
  addTitle: 'Add item',
  editTitle: 'Edit item',
  addDesc: `You are adding item to {teamName}`,
  editDesc: '{itemName}',
  addSaveButton: 'Add',
  editSaveButton: 'Save',
  labelItemOwnership: 'Item Ownership',
  collective: 'Collective',
  private: 'Private',
  addCollectiveDesc: `The item will belong to the team. IMPORTANT: You can't change it later.`,
  editCollectiveDesc: `The item belongs to the team. You can't change it anymore.`,
  addPrivateDesc: `The item will belong to you and you share it to your team. You can change ownership anytime`,
  editPrivateDesc: `The item belongs to you and you share it to your team. You can change ownership anytime`,
  labelItemName: 'Item name',
  labelItemDesc: 'Item description',
  cancel: 'Cancel',
})
