import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import ItemHandoverAssignmentForm from '../ItemHandoverAssignmentForm'
import storeAPI from '../../libs/gql/storeAPI'

const CREATE_ITEM_HANDOVER_ASSIGNMENT = gql`
  mutation CreateItemHandoverAssignment($itemId: String!, $receiverId: String!, $description: String!) {
    createItemHandoverAssignment(itemId: $itemId, receiverId: $receiverId, description: $description)
  }
`

export default function CreateItemHandoverAssignment(props) {
  const { children, user, item, team } = props
  const [open, setOpen] = React.useState(false)

  const [createItemHandoverAssignment, { loading, error }] = useMutation(CREATE_ITEM_HANDOVER_ASSIGNMENT, {
    update: (store, { data: { createItemHandoverAssignment } }) => {
      // const { channelData = {} } = createItemHandoverAssignment
      storeAPI.getNewMessage({
        store,
        data: {
          ...createItemHandoverAssignment,
          item: createItemHandoverAssignment.channelData.item,
          doUpdateUser: true,
          doUpdateChat: true,
        },
      })
      storeAPI.editItem({
        store,
        data: {
          updatedItem: {
            ...item,
            ...createItemHandoverAssignment.channelData.item,
          },
          itemId: item.id,
          teamId: item.teamId,
        },
      })
    },
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      {open ? (
        <ItemHandoverAssignmentForm
          formType="ADD"
          user={user}
          item={item}
          team={team}
          onSubmit={createItemHandoverAssignment}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </React.Fragment>
  )
}
