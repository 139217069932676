import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import Alert from '@material-ui/lab/Alert'
// import {makeStyles} from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import { updateApp } from '../../libs/redux/reducers/app'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import useForm from '../../libs/hooks/useForm'
import { useIntl } from 'react-intl'
import messages from './messages'

const INVITE_USER = gql`
  mutation InviteUser($email: String!, $teamId: String!, $teamName: String!) {
    inviteUser(email: $email, teamId: $teamId, teamName: $teamName)
  }
`

// const useStyles = makeStyles(theme => ({
//   triggerButton: {
//     fontSize: 30,
//   },
// }));

function InviteUser(props) {
  const { setFeedback, team, update } = props
  const teamId = team.id
  // const classes = useStyles();
  const { formatMessage } = useIntl()
  const [open, setOpen] = React.useState(false)

  const { form, fieldProps, setField, validate } = useForm({
    email: {
      value: '',
      error: '',
      required: true,
      validation: [
        {
          regExp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'The email is not valid',
        },
      ],
    },
  })
  const [inviteUser, { loading, error }] = useMutation(INVITE_USER, {
    update,
  })

  const submit = async () => {
    const { hasErrors } = validate()
    if (!hasErrors) {
      try {
        const {
          data: {
            inviteUser: { message: inviteUserMessage },
          },
        } = await inviteUser({
          variables: {
            teamId,
            teamName: team.name,
            email: form.email.value,
          },
        })
        console.log('Invitation', inviteUserMessage)
        setField({ field: 'email', key: 'value', value: '' })
        setFeedback({
          open: true,
          status: 'success',
          text: formatMessage({ ...messages.success }),
        })
        setOpen(false)
      } catch (e) {
        console.log('invite user', e)
        setFeedback({
          open: true,
          status: 'error',
          text: formatMessage({ ...messages.error }),
        })
      }
    }
  }

  return (
    <React.Fragment>
      {loading ? <Loader loading /> : null}
      <Button
        size="small"
        style={{ fontSize: 12 }}
        color="primary"
        variant="outlined"
        startIcon={<PersonAddIcon />}
        onClick={() => {
          setOpen(true)
        }}
      >
        {formatMessage({ ...messages.buttonLabel })}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ ...messages.dialogTitle })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage({ ...messages.dialogDescription }, { name: team.name })}</DialogContentText>
          <form
            noValidate
            onSubmit={async e => {
              e.preventDefault()
              submit()
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={formatMessage({ ...messages.dialogFieldEmail })}
              email="email"
              autoComplete="email"
              autoFocus
              {...fieldProps({
                field: 'email',
                customError: null,
              })}
            />
          </form>

          {error ? (
            <Alert severity="error">
              <ErrorMessage error={error} />
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setField({ field: 'email', key: 'value', value: '' })
              setOpen(false)
            }}
            color="primary"
          >
            {formatMessage({ ...messages.dialogCancelButton })}
          </Button>
          <Button
            onClick={() => {
              submit()
            }}
            color="primary"
          >
            {formatMessage({ ...messages.dialogInviteButton })}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispatch => ({
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteUser)
