import * as PusherPushNotifications from '@pusher/push-notifications-web'
import auth from '../auth'

const { REACT_APP_API } = process.env
let hasAttempedToRegister = false
let hasRegistered = false
export default function pusherPushNotifications(userId) {
  // return {
  //   stop: () => Promise.resolve(),
  //   getInstance: () => ({
  //     stop: () => Promise.resolve(),
  //     addDeviceInterest: () => {},
  //   }),
  // }
  let beamsClient
  const token = auth.getTokenId()
  if (token && userId && !hasAttempedToRegister) {
    console.log('beams', 'registered')
    hasAttempedToRegister = true
    beamsClient = new PusherPushNotifications.Client({
      instanceId: '71ddad18-66e3-47eb-87c9-772acba99c7d',
    })
    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: `${REACT_APP_API}/api/beansAuth`,
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    })
    beamsClient
      .start()
      .then(() => beamsClient.setUserId(userId, beamsTokenProvider))
      .then(() => beamsClient.addDeviceInterest('hello'))
      .then(() => {
        hasRegistered = true
        console.log('beams', 'Successfully registered and subscribed!')
      })
      .catch(e => {
        console.error('beams', e)
      })
  } else {
    if (beamsClient) {
      hasRegistered = false
      hasAttempedToRegister = false
      beamsClient.stop().catch(console.error)
      console.log('beams', 'unregistered')
    }
    if (hasRegistered) {
      hasRegistered = false
      hasAttempedToRegister = false
      console.log('beams', 'unregistered')
    }
  }
  return {
    stop: () => {
      if (beamsClient && hasRegistered) {
        hasRegistered = false
        hasAttempedToRegister = false
        console.log('beams', 'unregistered')
        return beamsClient.stop()
      }
      return Promise.resolve()
    },
    getInstance: () => beamsClient,
  }
}
