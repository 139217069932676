import React from 'react'
import { useHistory } from 'react-router-dom'
import ChatChannels from '../ChatChannels'

export default function TeamChat(props) {
  const { team, userMeta, app } = props
  const history = useHistory()

  return (
    <ChatChannels
      userMeta={userMeta}
      app={app}
      team={team}
      onClick={data => {
        history.push(`/team/${team.id}/item/${data.item.id}/tab/chat`)
      }}
    />
  )
}
