import React from 'react'
import getErrorMessage from '../../libs/getErrorMessage'
import { useIntl } from 'react-intl'
export default function ErrorMessage(props) {
  const { error } = props
  const { formatMessage } = useIntl()

  return !error ? (
    props.children
  ) : (
    <span dangerouslySetInnerHTML={{ __html: getErrorMessage(error, formatMessage) }} />
  )
}
