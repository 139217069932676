import USER_QUERY from '../USER_QUERY'
import TEAM from '../TEAM'

const updateUserMeta = ({ store, data: { updatedTeam } }) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)

  const { teams = [] } = data
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        teams: teams.map(team => {
          if (team.id === updatedTeam.id) {
            return {
              team,
              ...updatedTeam,
            }
          }
          return team
        }),
      },
    },
  })
}

const updateTeam = ({ store, data: { updatedTeam } }) => {
  const queryProps = { query: TEAM, variables: { id: updatedTeam.id } }
  const data = store.readQuery(queryProps)

  console.log('updatedTeam', data, updatedTeam)

  store.writeQuery({
    ...queryProps,
    data: {
      team: {
        ...data.team,
        ...updatedTeam,
      },
    },
  })
}

const editTeam = data => {
  try {
    updateUserMeta(data)
  } catch (e) {
    console.log('editTeam updateUserMeta', e)
  }
  try {
    updateTeam(data)
  } catch (e) {
    console.log('editTeam updatedTeam', e)
  }
}

export default editTeam
