import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import { useLazyQuery } from '@apollo/client'
import TEAM_ITEMS from '../../libs/gql/TEAM_ITEMS'
import useDebounce from '../../libs/hooks/useDebounce'

export default function SearchItems(props) {
  const { team, onClick } = props
  // const [value, setValue] = React.useState()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])

  const debouncedSearchText = useDebounce(inputValue, 1000)

  const [searchItems, { /* called, loading,  */ data }] = useLazyQuery(TEAM_ITEMS, {
    variables: {
      teamId: team.id,
      page: 1,
      search: debouncedSearchText.length > 4 ? debouncedSearchText : '',
    },
  })

  React.useEffect(() => {
    if (data?.itemsByTeam) {
      const { itemsByTeam = [] } = data
      setOptions(itemsByTeam)
    }
  }, [data])

  React.useEffect(() => {
    if (debouncedSearchText.length > 4) {
      searchItems()
    } else if (options.length) {
      setOptions([])
    }
  }, [debouncedSearchText, searchItems, setOptions]) // eslint-disable-line

  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      <Autocomplete
        size="small"
        clearOnBlur={false}
        fullWidth
        onChange={(event, newValue) => {
          onClick(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="search-items"
        options={options}
        getOptionLabel={option => option.name || ''}
        renderInput={params => <TextField {...params} label="Search Items" variant="outlined" />}
        renderOption={option => {
          return (
            <div>
              <Typography display="block" noWrap>
                {option.name}
              </Typography>
              {option.borrower ? (
                <Typography variant="caption" display="block">
                  {option.borrower.name}
                </Typography>
              ) : null}
            </div>
          )
        }}
      />
    </div>
  )
}
