import TEAM from '../TEAM'
import USER_QUERY from '../USER_QUERY'

const updateUserMeta = ({ store, data: { newItem } }) => {
  const queryProps = { query: USER_QUERY }
  const data = store.readQuery(queryProps)
  const newData = {
    ...data,
    userMeta: {
      ...data.userMeta,
      items: [...data.userMeta.items, newItem],
    },
  }
  // console.log('NEW_ITEM', data, newData)
  store.writeQuery({
    ...queryProps,
    data: newData,
  })
}

const updateTeam = ({ store, data: { teamId, newItem } }) => {
  const queryProps = { query: TEAM, variables: { id: teamId } }
  const data = store.readQuery(queryProps)
  if (!data.team.items.find(item => item.id === newItem.id)) {
    const newData = {
      ...data,
      team: {
        ...data.team,
        numberOfItems: data.team.numberOfItems + 1,
        items: [...data.team.items, newItem],
      },
    }
    store.writeQuery({
      ...queryProps,
      data: newData,
    })
  }
}

const addItem = ({ store, data }) => {
  if (data.updateUserMeta) {
    updateUserMeta({ store, data })
  }
  if (data.updateTeam) {
    updateTeam({ store, data })
  }
}

export default addItem
