import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Header from '../Header'
import Footer from '../Footer'

function RestrictedPageBody(props) {
  const {
    error,
    auth: { user },
  } = props

  const errorMeta = (() => {
    if (error) {
      const errorStr = new RegExp(/ERROR:{(.*)}/, 'g').exec(error.toString())
      if (errorStr && errorStr[1]) {
        try {
          return JSON.parse(errorStr[1])
        } catch (e) {
          return
        }
      }
    }
    return
  })()
  const MESSAGES = {
    IT_IS_RESTRICTED: 'This page is restricted',
  }
  const message = (() => {
    if (errorMeta && errorMeta.MESSAGE_CODE && MESSAGES[errorMeta.MESSAGE_CODE]) {
      return MESSAGES[errorMeta.MESSAGE_CODE]
    } else if (errorMeta && errorMeta.MESSAGE_CODE) {
      return errorMeta.MESSAGE_CODE
    }

    return ''
  })()
  if (!user.id) {
    return <Redirect to="/" />
  }
  return (
    <React.Fragment>
      <Header />
      <Container style={{ padding: 10 }}>{!error ? props.children : message}</Container>
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = null
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestrictedPageBody)
