import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Sign up',
  emailLabel: 'Email address',
  EMAIL_IS_USED: 'This email is already used',
  passwordLabel: 'Password',
  termConditionsLabel: 'Yes, I agree to terms and conditions',
  termConditionsError: 'You have to read and agree to the terms and conditions',
  termConditionsTextDisagree: 'Disagree',
  termConditionsTextAgree: 'Agree',
  signupButton: 'Sign up',
  forgotPassword: 'Forgot password?',
  login: 'Login',
  termConditionsHelpText: 'Read all of it if you want to agree',
  termConditionsTitle: 'Terms and conditions',
  termConditionsText: `
                <p>
                Let Google help apps determine location. This means sending anonymous location data to Google, even when
                no apps are running.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non dictum sem, in
                efficitur tortor. Fusce semper convallis turpis, eget suscipit libero blandit ut. Vestibulum consectetur
                ex ac turpis egestas, sit amet ultrices lectus feugiat. Donec fringilla nisi scelerisque interdum
                pellentesque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer ac nibh sit amet
                urna finibus blandit a vitae erat. Cras ultrices rutrum magna efficitur dignissim. Quisque faucibus nec
                risus et imperdiet. Duis at ligula ac nibh vehicula rutrum. Vestibulum id lectus in nibh sagittis
                elementum. Sed porttitor massa erat, nec placerat diam viverra et. Class aptent taciti sociosqu ad
                </p>
                <p>
                litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse vel magna arcu. Pellentesque ac
                leo vel magna dictum sagittis. Vestibulum semper gravida iaculis. Maecenas nec elit fermentum, elementum
                odio vel, facilisis risus. Maecenas at ultricies nunc. Sed enim est, bibendum id semper vel, euismod et
                eros. Donec sollicitudin a urna et mattis. Nunc luctus suscipit leo nec ullamcorper. Phasellus vel nisi
                at augue cursus tristique nec sit amet dui. Nunc ac ligula mattis, feugiat urna id, mollis ante. Sed
                maximus lacus lacus, dignissim ultrices odio tempus in. Donec nec consectetur libero, et aliquet est. In
                in ullamcorper tellus, sed porta metus. Phasellus tristique, mauris vel volutpat bibendum, erat risus
                interdum libero, in interdum nibh lacus in lorem. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Proin interdum suscipit augue non feugiat. Morbi sollicitudin lacus in nunc sodales aliquet.
                </p>
                <p>
                Vestibulum id purus diam. Nam elementum tincidunt augue in sollicitudin. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Quisque mattis leo felis, ut pretium ante placerat ut. In hac habitasse
                platea dictumst. Nunc lobortis vel ipsum et tempor. Proin vitae enim mauris. Nunc sit amet molestie
                magna. Nunc vitae felis eleifend, ultricies nisi vitae, blandit nisi. Etiam consequat et sem vel
                volutpat. Curabitur id cursus tellus. Sed varius sit amet ipsum at viverra. Cras lobortis vel sem ut
                viverra. Duis maximus nisl tempus elementum sagittis. Maecenas ultrices purus at commodo eleifend.
                Maecenas non sodales purus. Sed id est vehicula, egestas massa quis, consectetur velit. Donec bibendum
                magna vel augue consequat, ultrices feugiat leo pharetra. Donec molestie ultrices tellus quis semper.
                Aenean non lorem leo. Phasellus purus risus, gravida et varius nec, pellentesque non neque. Nullam
                condimentum aliquet erat ac porttitor. Cras molestie faucibus ante, ac imperdiet nulla eleifend vel.
                Etiam rhoncus euismod erat non rutrum. Ut id velit ut est imperdiet aliquet vel ut orci. Nunc mattis mi
                sollicitudin, egestas augue et, hendrerit nibh.
                </p>
    `,
})
