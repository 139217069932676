import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
// import ErrorMessage from '../ErrorMessage'
// import ItemHandoverAssignmentForm from '../ItemHandoverAssignmentForm'
import storeAPI from '../../libs/gql/storeAPI'

const SEND_ITEM_HANDOVER_REQUEST = gql`
  mutation SendItemHandoverRequest($id: String!) {
    sendItemHandoverRequest(id: $id)
  }
`

export default function SendItemHandoverRequest(props) {
  const { children, item/* , team, user */ } = props
  // const [open, setOpen] = React.useState(false)

  const [sendItemHandoverRequest, { loading/* , error */ }] = useMutation(SEND_ITEM_HANDOVER_REQUEST, {
    update: (store, { data: { sendItemHandoverRequest } }) => {
      // const { channelData = {} } = sendItemHandoverRequest
      storeAPI.getNewMessage({
        store,
        data: { ...sendItemHandoverRequest, item, doUpdateUser: true, doUpdateChat: true },
      })
      // storeAPI.editItem({
      //   store,
      //   data: {
      //     updatedItem: {
      //       ...item,
      //     },
      //     itemId: item.id,
      //     teamId: item.teamId,
      //   },
      // })
    },
  })

  if (loading) return <Loader loading />
  // if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        sendItemHandoverRequest: () => {
          try {
            sendItemHandoverRequest({
              variables: {
                id: item.itemHandoverAssignment.id.toString(),
              },
            })
          } catch (e) {
            console.log('sendItemHandoverRequest', e)
          }
        },
      })}
    </React.Fragment>
  )
}
