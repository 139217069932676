// import React from 'react'
// import Box from '@material-ui/core/Box'

export default function TabPanel(props) {
  const { children, value, index } = props
  if (value !== index) {
    return null
  }

  return children
}
