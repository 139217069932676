import React from 'react'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Loader from '../Loader'
import { useIntl } from 'react-intl'
import messages from './messages'

const { REACT_APP_IMAGE_API, REACT_APP_IMAGE_CLIENT_ID } = process.env
const headers = {
  Authorization: `Client-ID ${REACT_APP_IMAGE_CLIENT_ID}`,
  Accept: 'application/json',
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const items = reader.result.split('base64,')
      resolve(items[1])
    }
    reader.onerror = error => reject(error)
  })

async function upload(file) {
  const dataBase64 = await toBase64(file)
  const postData = {
    image: dataBase64,
    type: 'base64',
  }

  const config = {
    headers,
  }

  return axios.post(REACT_APP_IMAGE_API, postData, config)
}

async function deleteImage(deletehash) {
  const config = {
    headers,
  }
  return axios.delete(`${REACT_APP_IMAGE_API}/${deletehash}`, config)
}

export default function UploadImage(props) {
  const { onChange, initialImageData = null } = props
  const [image, setImage] = React.useState(initialImageData)
  const [loading, setLoading] = React.useState(null)
  const { formatMessage } = useIntl()
  React.useEffect(() => {
    const data = image
      ? {
          id: image.id,
          link: image.link,
          deletehash: image.deletehash,
          width: image.width,
          height: image.height,
        }
      : null
    onChange(data)
  }, [image]) // eslint-disable-line react-hooks/exhaustive-deps

  if (image?.link) {
    return (
      <Card style={{ width: 110 }}>
        <CardContent>
          <img src={image.link} width="80" alt="" />
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            size="small"
            onClick={async () => {
              setLoading(true)
              await deleteImage(image.deletehash)
              setLoading(false)
              setImage(null)
            }}
          >
            {formatMessage({ ...messages.remove })}
          </Button>
        </CardActions>
      </Card>
    )
  }
  return (
    <label htmlFor="upload-photo">
      <Loader loading={loading} />
      <input
        style={{ display: 'none' }}
        id="upload-photo"
        name="upload-photo"
        type="file"
        onChange={async e => {
          const file = e.target.files[0]
          if (file) {
            setLoading(true)
            const res = await upload(file)
            setLoading(false)
            const {
              data: { data: imageData },
            } = res
            setImage(imageData)
          }
        }}
      />

      <Button disableElevation size="small" component="span" aria-label="add" variant="contained">
        <AddIcon />
        {formatMessage({ ...messages.upload })}
      </Button>
    </label>
  )
}
