import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import ItemForm from '../ItemForm'

const EDIT_ITEM = gql`
  mutation EditItem(
    $itemId: String!
    $teamId: String!
    $name: String!
    $ownership: String!
    $description: String!
    $isAvailable: Boolean
    $image: JSON
  ) {
    editItem(
      itemId: $itemId
      teamId: $teamId
      name: $name
      ownership: $ownership
      description: $description
      isAvailable: $isAvailable
      image: $image
    )
  }
`

export default function EditItem(props) {
  const { children, item, team, update } = props
  const [open, setOpen] = React.useState(false)

  const [editItem, { loading, error/* , data */ }] = useMutation(EDIT_ITEM, {
      update,
    // refetchQueries,
    // awaitRefetchQueries: true,
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      {open ? (
        <ItemForm formType="EDIT" item={item} team={team} onSubmit={editItem} open={open} setOpen={setOpen} />
      ) : null}
    </React.Fragment>
  )
}
