import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
// import history from './history';
import auth from '../../libs/auth'

const { REACT_APP_API } = process.env

const httpLink = createHttpLink({
  uri: `${REACT_APP_API}/graphql`,
  // credentials: 'include',
  // headers: {
  //   'Access-Control-Allow-Credentials': true,
  // },
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = auth.getTokenId()
  // return the headers to the context so httpLink can read them
  // console.log('LALA', token)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      const {
        extensions: { code },
        message,
        locations,
        path,
      } = error

      const errorMeta = (() => {
        if (error) {
          const errorStr = new RegExp(/ERROR:{(.*)}/, 'g').exec(message.toString())
          if (errorStr && errorStr[1]) {
            try {
              return JSON.parse(errorStr[1])
            } catch (e) {
              return
            }
          }
        }
        return
      })()
      if (code === 'UNAUTHENTICATED' || (errorMeta && errorMeta.MESSAGE_CODE === 'IT_IS_RESTRICTED')) {
        console.log('redirect')

        auth.logout()
        // localStorage.removeItem('tokenId');
        // history.push('/login');
      }
      if (errorMeta && errorMeta.MESSAGE_CODE === 'SERVER_QUERY_ERROR') {
        console.error('------------------------')
        console.error(errorMeta.MESSAGE_CODE)
        console.error('------------------------')
        console.error(errorMeta.QUERY)
        console.error('------------------------')
        console.error(errorMeta.ERROR_MESSAGE)
        console.error('------------------------')
      } else {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, code, error)
      }
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
// {
//     typePolicies: {
//       Query: {
//         fields: {
//           fieldName: {
//             read: fieldName => {
//               return fieldName || null
//             },
//           },
//         },
//       },
//     },
//   }

  // credentials: 'include',
  // request: operation => {
  //   const token = localStorage.getItem('tokenId');
  //   console.log('LALA', token);
  //   operation.setContext({
  //     headers: {
  //       authorization: token ? `Bearer ${token}` : '',
  //     },
  //   });
  // },
})

export default client
