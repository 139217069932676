import { defineMessages } from 'react-intl'

export default defineMessages({
  addTitle: 'Create Team',
  editTitle: 'Edit Team',
  addSaveButton: 'Add',
  editSaveButton: 'Save',
  labelTeamName: 'Item name',
  cancelButton: 'Cancel',
})
