import PubSub from 'pubsub-js'
import USER_QUERY from '../USER_QUERY'

const updateUserMeta = ({ store, data: { newItem, channelData } }) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)
  const { items = [] } = data

  const newChatChannels = (() => {
    let hasChanged = false
    const newData = data.chatChannels.map(c => {
      if (c.topic === 'ITEM' && c.item.id === channelData?.item?.id) {
        hasChanged = true
        return channelData
      }
      return c
    })
    if (!hasChanged) {
      return [...newData, channelData]
    }

    return newData
  })()
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        items: [newItem, ...items],
        chatChannels: newChatChannels,
      },
    },
  })
}

const updateTeam = ({ store, data: { newItem: updatedItem, teamId } }) => {
  PubSub.publish('itemHasBeenUpdated', { updatedItem })
}
const getItem = ({ store, data }) => {
  console.log('storeAPI getItem', data)
  updateUserMeta({ store, data })
  try {
    updateTeam({ store, data })
  } catch (e) {
    console.log(e)
  }
}

export default getItem
