import React from 'react'
import PubSub from 'pubsub-js'
import { useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TEAM_ITEMS from '../../libs/gql/TEAM_ITEMS'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
// import moment from 'moment'
// import { useQuery } from '@apollo/client'
// import { makeStyles } from '@material-ui/core/styles'
import ItemsList from '../ItemsList'

// const useStyles = makeStyles(theme => ({}))

export default function TeamItems(props) {
  const { team } = props

  const [page, setPage] = React.useState(1)
  const [prevPage, setPrevPage] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)
  const [items, setItems] = React.useState([])
  const { loading, error, data } = useQuery(TEAM_ITEMS, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: team.id,
      page,
    },
  })

  React.useEffect(() => {
    const pubsubToken = PubSub.subscribe('itemHasBeenUpdated', (msg, data) => {
      const { updatedItem } = data

      console.log(
        'itemHasBeenUpdated',
        updatedItem,
        items,
        items.map(item => {
          if (item.id === updatedItem.id) {
            return {
              ...item,
              ...updatedItem,
            }
          }
          return item
        }),
      )
      if (!updatedItem) {
        return
      }

      setItems(
        items.map(item => {
          if (item.id === updatedItem.id) {
            return {
              ...item,
              ...updatedItem,
            }
          }
          return item
        }),
      )
    })

    return () => {
      PubSub.unsubscribe(pubsubToken)
    }
  }, [items]) // eslint-disable-line

  React.useEffect(() => {
    if (data?.itemsByTeam) {
      const { itemsByTeam = [] } = data
      const newItems = (() => {
        if (page > prevPage) {
          return [...items, ...itemsByTeam]
        }

        return items
      })()
      setItems(newItems)
      setPrevPage(page)
      if (newItems.length === team.numberOfItems || data?.itemsByTeam?.length === 0) {
        setHasMore(false)
      }
    }
  }, [data]) // eslint-disable-line

  if (loading && !items.length) return <Loader loading />
  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <React.Fragment>
      <ItemsList items={items} {...props} />
      {hasMore ? (
        <Grid container justify="center" spacing={1}>
          <Grid item xs={6}>
            <br />
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={() => {
                setPage(page + 1)
              }}
            >
              Load more
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {loading && items.length ? <Loader loading /> : null}
    </React.Fragment>
  )
}
