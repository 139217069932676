import messages from './messages'
import moment from 'moment'

export default function getErrorMessage(error, formatMessage = () => null) {
  const MESSAGES = {
    HAS_ALREADY_INVITED: () => formatMessage({ ...messages.HAS_ALREADY_INVITED }),
    IS_ALREADY_MEMBER: (data = {}) =>
      formatMessage(
        { ...messages.IS_ALREADY_MEMBER },
        {
          teamName: `<b>${data.teamName}</b>`,
        },
      ),
    INVITED_YOURSELF: () => formatMessage({ ...messages.INVITED_YOURSELF }),
    REQUESTED_ALREADY: (data = {}) =>
      formatMessage(
        { ...messages.REQUESTED_ALREADY },
        {
          date: moment(data.addedAt).format('LLLL'),
        },
      ),
  }
  const errorMeta = (() => {
    if (error) {
      const errorStr = new RegExp(/ERROR:{(.*)}/, 'g').exec(error.toString())
      if (errorStr && errorStr[1]) {
        try {
          return JSON.parse(errorStr[1])
        } catch (e) {
          return
        }
      }
    }
    return
  })()
  const message = (() => {
    if (errorMeta?.MESSAGE_CODE) {
      return MESSAGES[errorMeta.MESSAGE_CODE]
        ? MESSAGES[errorMeta.MESSAGE_CODE](errorMeta.DATA)
        : errorMeta.MESSAGE_CODE
    }

    return ''
  })()

  return message
}
