// import { addLocaleData } from 'react-intl'
// import locale_en from 'react-intl/locale-data/en'
// import locale_se from 'react-intl/locale-data/se'

import messages_el from './translations/el.json'
import messages_en from './translations/en.json'

// addLocaleData([...locale_en, ...locale_se])

export const messages = {
    el: messages_el,
    en: messages_en,
}

