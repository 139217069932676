import { defineMessages } from 'react-intl'

export default defineMessages({
  buttonLabel: 'Invite member',
  dialogTitle: 'Invite user',
  dialogDescription: 'Invite user to {name}',
  dialogFieldEmail: 'Email',
  dialogCancelButton: 'Cancel',
  dialogInviteButton: 'Invite',
  success: 'Invitation has been sent successfully',
  error: 'Invitation has not been sent',
})
