import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import { updateApp } from '../../libs/redux/reducers/app'
import { useIntl } from 'react-intl'
import messages from './messages'

const REJECT_INVITATION = gql`
  mutation RejectInvitation($teamId: String!) {
    rejectInvitation(teamId: $teamId)
  }
`

function RejectInvitation(props) {
  const { setFeedback, children, update } = props

  const { formatMessage } = useIntl()
  const [rejectInvitation, rejectInvitationResponse] = useMutation(REJECT_INVITATION, {
    update,
  })

  return (
    <React.Fragment>
      {children({
        ...props,
        rejectInvitation: async variables => {
          try {
            await rejectInvitation({ variables })
            setFeedback({
              open: true,
              status: 'success',
              text: formatMessage({ ...messages.success }),
            })
          } catch (e) {
            console.log('rejectInvitation', e)
            setFeedback({
              open: true,
              status: 'error',
              text: formatMessage({ ...messages.failed }),
            })
          }
        },
        rejectInvitationResponse,
      })}
    </React.Fragment>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispatch => ({
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RejectInvitation)
