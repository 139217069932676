import { defineMessages } from 'react-intl'

export default defineMessages({
  login: 'Log in',
  logout: 'Log out',
  myActions: 'My actions',
  myAccount: 'My account',
  dashboard: 'DashBoard',
  scan: 'Scan',
})
