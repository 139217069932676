import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import ItemHandoverAssignmentForm from '../ItemHandoverAssignmentForm'
import storeAPI from '../../libs/gql/storeAPI'

const EDIT_ITEM_HANDOVER_ASSIGNMENT = gql`
  mutation EditItemHandoverAssignment($id: String!, $itemId: String!, $receiverId: String!, $description: String!) {
    editItemHandoverAssignment(id: $id, itemId: $itemId, receiverId: $receiverId, description: $description)
  }
`

export default function EditItemHandoverAssignment(props) {
  const { children, user, item, team } = props
  const [open, setOpen] = React.useState(false)

  const [editItemHandoverAssignment, { loading, error }] = useMutation(EDIT_ITEM_HANDOVER_ASSIGNMENT, {
    update: (store, { data: { editItemHandoverAssignment } }) => {
      // const { channelData = {} } = editItemHandoverAssignment
      storeAPI.getNewMessage({
        store,
        data: { ...editItemHandoverAssignment, item, doUpdateUser: true, doUpdateChat: true },
      })
      storeAPI.editItem({
        store,
        data: {
          updatedItem: {
            ...item,
            ...editItemHandoverAssignment.channelData.item,
          },
          itemId: item.id,
          teamId: item.teamId,
        },
      })
    },
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      {open ? (
        <ItemHandoverAssignmentForm
          formType="EDIT"
          user={user}
          item={item}
          team={team}
          onSubmit={editItemHandoverAssignment}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </React.Fragment>
  )
}
