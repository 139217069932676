import PubSub from 'pubsub-js'
import ITEM_DETAILS from '../ITEM_DETAILS'
import USER_QUERY from '../USER_QUERY'

const updateItemDetails = ({ store, data: { updatedItem, itemId } }) => {
  const queryProps = { query: ITEM_DETAILS, variables: { id: itemId } }
  const data = store.readQuery(queryProps)
  const newData = {
    ...data,
    item: {
      ...data.item,
      ...updatedItem,
    },
  }
  store.writeQuery({
    ...queryProps,
    data: newData,
  })
}

const updateUserMeta = ({ store, data: { updatedItem, teamId } }) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        chatChannels: data.chatChannels.map(chatChannel => {
          if (chatChannel.topic === 'ITEM' && chatChannel.item.id === updatedItem.id) {
            return {
              ...chatChannel,
              item: {
                ...chatChannel.item,
                ...updatedItem,
              },
            }
          }
          return chatChannel
        }),
        items: data.items.filter(item => item.id !== updatedItem.id),
      },
    },
  })
}

const updateTeam = ({ store, data: { updatedItem, teamId } }) => {
  PubSub.publish('itemHasBeenUpdated', { updatedItem })
}

const removeItem = props => {
  try {
    console.log('editItem', props)
    updateItemDetails(props)
    updateUserMeta(props)
    updateTeam(props)
  } catch (e) {
    console.log(e)
  }
}

export default removeItem
