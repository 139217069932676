import React from 'react'
// import moment from 'moment'
// import { useQuery } from '@apollo/client'
import { useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
// import Avatar from '@material-ui/core/Avatar'
import ITEM_MESSAGES from '../../libs/gql/ITEM_MESSAGES'
import storeAPI from '../../libs/gql/storeAPI'
import getAvatarUrl from '../../libs/avatarUrl'
import Chat from '../Chat'
import Loader from '../Loader'
import AcceptItemHandoverRequest, { getExpiredMessage } from '../AcceptItemHandoverRequest'
import { useIntl } from 'react-intl'
import messages from './messages'

export default function ItemChat(props) {
  const { item, user } = props

  const { formatMessage } = useIntl()
  const { loading, error, data, refetch } = useQuery(ITEM_MESSAGES, {
    variables: {
      id: item.id,
    },
    fetchPolicy: 'network-only',
  })

  const myself = ({ u, me, i }) => {
    if (user.id !== u.id) {
      return u.name || u.nam || ''
    }

    if (me) {
      return formatMessage({ ...messages.me })
    }
    if (i) {
      return formatMessage({ ...messages.i })
    }

    return u.name
  }

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`
  console.log('ITEM', 'MESSAGES', data)
  const {
    itemMessages: { messages: itemMessages = [] },
  } = data

  let showSendHandoverRequestButton = true

  const getAvatar = u => {
    const text = u.nam || u.name
    return `<img alt="${text}" src="${getAvatarUrl({
      seed: u.id,
    })}" style="width:20px;height:20px;margin-right:5px" align="center" />`
  }
  return (
    <Chat
      {...props}
      channelName={`chat_item_${item.id}`}
      channelHandler={({ user, data }) => {
        console.log('ws-', 'chat', 'chat_item_$', data, user.id)
        if (user.id !== data.newMessage.u.id) {
          refetch()
        }
      }}
      messages={itemMessages.map(message => {
        if (!message.type) {
          return message
        }

        if (message.type === 'EDIT_HANDOVER_ASSIGNMENT') {
          return {
            ...message,
            txt: formatMessage(
              { ...messages.EDIT_HANDOVER_ASSIGNMENT },
              {
                userAvatar: getAvatar(message.u),
                userName: `<b>${myself({ u: message.u, i: true })}</b>`,
                receiverAvatar: getAvatar(message.data.receiver),
                receiverName: `<b>${message.data.receiver.name}</b>`,
                itemName: `<b>${message.data.item.name}</b>`,
                message: `<i>${message.data.description}</i>`,
                breakLine: '<br />',
              },
            ),
          }
        }
        if (message.type === 'NEW_HANDOVER_ASSIGNMENT') {
          return {
            ...message,
            txt: formatMessage(
              { ...messages.NEW_HANDOVER_ASSIGNMENT },
              {
                userAvatar: getAvatar(message.u),
                userName: `<b>${myself({ u: message.u, i: true })}</b>`,
                receiverAvatar: getAvatar(message.data?.receiver),
                receiverName: `<b>${message.data?.receiver?.name}</b>`,
                itemName: `<b>${message.data?.item?.name}</b>`,
                message: `<i>${message.data?.description}</i>`,
                breakLine: '<br />',
              },
            ),
          }
        }
        if (message.type === 'SEND_HANDOVER_REQUEST') {
          const show = (() => {
            if (showSendHandoverRequestButton) {
              showSendHandoverRequestButton = false
              return true
            }

            return false
          })()
          return {
            ...message,
            txt: formatMessage(
              { ...messages.SEND_HANDOVER_REQUEST },
              {
                userAvatar: getAvatar(message.u),
                userName: `<b>${myself({ u: message.u, i: true })}</b>`,
                receiverAvatar: getAvatar(message.data?.receiver),
                receiverName: `<b>${myself({
                  u: message.data?.receiver,
                  me: true,
                })}</b>`,
                itemName: `<b>${message.data?.item?.name}</b>`,
              },
            ),
            footer:
              message?.data?.id === item?.itemHandoverAssignment?.id ? (
                <AcceptItemHandoverRequest item={item} inChat>
                  {({ acceptItemHandoverRequest }) =>
                    show ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={() => {
                          try {
                            acceptItemHandoverRequest({
                              itemId: item.id,
                            })
                          } catch (e) {
                            console.log('acceptItemHandoverRequest', e)
                          }
                        }}
                      >
                        {formatMessage({ ...messages.acceptButton })}
                      </Button>
                    ) : getExpiredMessage(formatMessage)
                  }
                </AcceptItemHandoverRequest>
              ) : null,
          }
        }
        if (message.type === 'ITEM_HANDOVER') {
          return {
            ...message,
            txt: formatMessage(
              { ...messages.ITEM_HANDOVER },
              {
                userAvatar: getAvatar(message.u),
                userName: `<b>${myself({ u: message.u, me: true })}</b>`,
                borrowerAvatar: getAvatar(message.data?.borrower),
                borrowerName: `<b>${myself({
                  u: message.data?.borrower,
                  i: true,
                })}</b>`,
                itemName: `<b>${message.data?.item?.name}</b>`,
                breakLine: '<br />',
              },
            ),
          }
        }
        return message
      })}
      update={(store, { data: { sendMessageAboutItem } }) => {
        console.log('getNewMessage', { ...sendMessageAboutItem, item, doUpdateUser: true, doUpdateChat: true })
        storeAPI.getNewMessage({
          store,
          data: { ...sendMessageAboutItem, item, doUpdateUser: true, doUpdateChat: true },
        })
      }}
    />
  )
}
