import USER_QUERY from '../USER_QUERY'

const rejectInvitation = ({ store, data: { invitation } }) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        teamInvitations: data.teamInvitations.filter(i => i.team.id !== invitation.team.id),
      },
    },
  })
}

export default rejectInvitation
