import USER_QUERY from '../USER_QUERY'

const updateUserMeta = ({
  store,
  data: {
    invitation,
    acceptInvitationRes: { team },
  },
}) => {
  const queryProps = { query: USER_QUERY }
  const { userMeta: data } = store.readQuery(queryProps)
  store.writeQuery({
    ...queryProps,
    data: {
      userMeta: {
        ...data,
        teams: [team, ...data.teams],
        teamInvitations: data.teamInvitations.filter(i => i.team.id !== invitation.team.id),
      },
    },
  })
}

export default function acceptInvitation(props) {
  updateUserMeta(props)
}
