import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useForm from '../../libs/hooks/useForm'
import { useIntl } from 'react-intl'
import messages from './messages'

export default function TeamForm(props) {
  const { team, formType = 'ADD', onSubmit, open, setOpen } = props
  const { formatMessage } = useIntl()
  const { form, fieldProps, setField, validate } = useForm({
    name: {
      value: team ? team.name : '',
      error: '',
      required: true,
    },
  })

  const texts = (() => {
    if (formType === 'ADD') {
      return {
        title: formatMessage({ ...messages.addTitle }),
        button: formatMessage({ ...messages.addSaveButton }),
      }
    }

    return {
      title: formatMessage({ ...messages.editTitle }),
      button: formatMessage({ ...messages.editSaveButton }),
    }
  })()

  const submit = async () => {
    const { hasErrors } = validate()
    if (!hasErrors) {
      try {
        const variables = (() => {
          if (formType === 'ADD') {
            return {}
          }

          return {
            id: team.id,
          }
        })()
        await onSubmit({
          variables: {
            ...variables,
            name: form.name.value,
          },
        })
        setOpen(false)
        setField({ field: 'name', key: 'value', value: '' })
      } catch (e) {
        console.log('formTeam', e)
      }
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
        <DialogContent>
          <form
            noValidate
            onSubmit={e => {
              e.preventDefault()
              submit()
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label={formatMessage({ ...messages.labelTeamName })}
              name="name"
              autoComplete={formatMessage({ ...messages.labelTeamName })}
              autoFocus
              {...fieldProps({
                field: 'name',
                customError: null,
              })}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setField({ field: 'name', key: 'value', value: '' })
              setOpen(false)
            }}
            color="primary"
          >
            {formatMessage({ ...messages.cancelButton })}
          </Button>
          <Button
            onClick={() => {
              submit()
            }}
            color="primary"
          >
            {texts.button}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
