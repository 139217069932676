import { defineMessages } from 'react-intl'

export default defineMessages({
  tabHome: 'Home',
  tabItems: 'Items',
  tabMembers: 'Members',
  tabChat: 'Chat',
  tabLogs: 'Logs',
  numberOfMembers: 'members',
  numberOfItems: 'items',
  addItem: 'Add Item',
  inYourPossession: 'In your possession',
})
