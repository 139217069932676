/* eslint-disable */
import React from 'react'
// import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
// import Drawer from '@material-ui/core/Drawer'
import { Divider } from '@material-ui/core'
import Copyright from '../Copyright'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.blue : theme.palette.blue,
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Divider />
      <footer className={classes.footer}>
        <Container>
          <Typography> Takina 2020</Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  )
}
