const getStore = () => {
    return {
        set: (name, value) => {
            localStorage.setItem(name, value)
        },
        get: (name) => {
           return localStorage.getItem(name)
        },
        remove: (name) => {
           return localStorage.removeItem(name)
        },
    }
}
const store = getStore()
export default store
