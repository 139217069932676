import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
// import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import ImageIcon from '@material-ui/icons/Image'
import { green, red, grey } from '@material-ui/core/colors'
// import Badge from '../Badge'
import ItemDetails from '../ItemDetails'
import ItemImage from '../ItemImage'
import ItemInfo from '../ItemInfo'
import { useIntl } from 'react-intl'
import messages from './messages'

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  title: {
    fontSize: 18,
    margin: '10px 0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  actionArea: {
    height: 250,
  },
  media: {
    height: 100,
  },
  status: {
    marginBottom: 5,
  },
  isAvailable: {
    color: green['500'],
    marginLeft: 5,
  },
  isNotAvailable: {
    color: red['500'],
    marginLeft: 5,
  },
  noImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey['100'],
    height: 100,
  },
  img: {
    height: 100,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey['100'],
  },
})

export default function ItemListItem(props) {
  const { data: item, team, isMine } = props
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const [itemDetailsOpen, setItemDetailsOpen] = React.useState()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.actionArea}>
        <ItemImage
          src={item?.image?.link}
          onClick={() => {
            setItemDetailsOpen(true)
          }}
        />
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {item.name}
        </Typography>

        <Divider />

        <ItemInfo team={team} item={item} isMine={isMine} />

        <Divider />

        {/* <Typography variant="body2" color="textSecondary" component="p"> */}
        {/*   {item.description} */}
        {/* </Typography> */}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          fullWidth
          onClick={() => {
            setItemDetailsOpen(true)
          }}
        >
          {formatMessage({ ...messages.detailsButton })}
        </Button>

        {itemDetailsOpen ? (
          <ItemDetails borrower team={team} item={item} setClose={() => setItemDetailsOpen(false)} />
        ) : null}
      </CardActions>
    </Card>
  )
}
