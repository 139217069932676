import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './libs/redux/store'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
/*
  <React.StrictMode>
  </React.StrictMode>,
  */
const { REACT_APP_VERCEL_GIT_COMMIT_MESSAGE, REACT_APP_VERCEL_GIT_COMMIT_SHA } = process.env
console.log('START', REACT_APP_VERCEL_GIT_COMMIT_SHA, REACT_APP_VERCEL_GIT_COMMIT_MESSAGE)
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()
// serviceWorker.register()
