import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import { gql, useMutation } from '@apollo/client'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Loader from '../Loader'
import EmojiPicker from '../EmojiPicker'
// import p2p from '../../libs/p2p'
import getAvatarUrl from '../../libs/avatarUrl'
import { getChannel } from '../../libs/pusher'
import { updateChat } from '../../libs/redux/reducers/app'

const SEND_MESSAGE_ABOUT_ITEM = gql`
  mutation SendMessageAboutItem($item: JSON!, $message: String!) {
    sendMessageAboutItem(item: $item, message: $message)
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  messages: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  messageLeft: {
    width: '100%',
    margin: '5px 0',
    display: 'flex',
  },
  messageRight: {
    width: '100%',
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  messageContainer: {
    width: '50%',
  },
  messageAvatar: {
    padding: 10,
  },
  messageText: {
    padding: 10,
    textAlign: 'left',
  },
}))

function updateScroll(element) {
  element.scrollTop = element.scrollHeight
}

function Chat(props) {
  const {
    app,
    setChat,
    item,
    team,
    auth: { user },
    messages,
    MessagesContainer,
    FieldContainer,
    update,
    channelName,
    channelHandler,
  } = props

  const channelHandlerFun = data => {
    console.log('ws-', 'chat', 'chat_item', data)
    channelHandler({
      user,
      data,
    })

    setChat({
      ...app.chat,
      [`chat_item_${item.id}`]: new Date().getTime(),
    })
  }

  React.useEffect(() => {
    setChat({
      ...app.chat,
      [`chat_item_${item.id}`]: new Date().getTime(),
    })
    const channel = getChannel(channelName)

    console.log('ws-chat', 'chat', 'subscribe', channelName)
    channel.bind('chat', channelHandlerFun)
    return () => {
      console.log('ws-chat-unsubscribe ws chat item', channelName)
      channel.unbind('chat', channelHandlerFun)
    }
  }, []) // eslint-disable-line

  console.log('CHAT', item)

  const classes = useStyles()
  const [sendMessageAboutItem, { loading, error /* , data */ }] = useMutation(SEND_MESSAGE_ABOUT_ITEM, {
    update,
    // refetchQueries,
    // awaitRefetchQueries: true,
  })
  const [message, setMessage] = React.useState('')
  const messagesContainer = React.createRef()
  const scrollHasUpdated = React.useRef(false)
  const lastMessageDate = React.useRef(null)
  const sortedMessages = messages
    .slice()
    .map(message => ({
      ...message,
      date: moment(message.SK.replace('message_'), 'YYYY-MM-DD-HH-mm-ss').valueOf(),
    }))
    .sort((a, b) => a.date - b.date)

  console.log('sortedMessages', sortedMessages)
  React.useEffect(() => {
    const hasNewMessage =
      sortedMessages.length &&
      sortedMessages[sortedMessages.length - 1] &&
      sortedMessages[sortedMessages.length - 1].date !== lastMessageDate.current
    if (messagesContainer.current && sortedMessages.length && (!scrollHasUpdated.current || hasNewMessage)) {
      updateScroll(messagesContainer.current)
      scrollHasUpdated.current = true
      lastMessageDate.current = sortedMessages[sortedMessages.length - 1].date
    }
  })

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`

  console.log('ITEM', 'CHAT', messages)

  const onSubmit = async () => {
    if (!message || !message.trim()) {
      return
    }
    try {
      await sendMessageAboutItem({
        variables: {
          message,
          item: {
            ...item,
            teamId: team.id,
          },
        },
      })

      // Object.keys(p2pConnUsers.current).forEach(c => c.send(message))

      scrollHasUpdated.current = false
    } catch (e) {
      console.log(e)
    }

    setMessage('')
  }
  return (
    <React.Fragment>
      <MessagesContainer ref={messagesContainer}>
        <div className={classes.root}>
          <div className={classes.messages}>
            {sortedMessages.map(message => {
              const userName = message?.u?.nam || 'unknown'
              const itsMe = message.u.id === user.id
              const avatar = (
                <Avatar
                  alt={userName}
                  src={getAvatarUrl({ seed: message.u.id })}
                  style={{ margin: itsMe ? '0 0 0 5px' : '0 5px 0 0', backgroundColor: '#f0f0f0' }}
                />
              )
              return (
                <Box
                  key={`message-${message.SK}-${message.u.id}`}
                  className={classes[`message${itsMe ? 'Right' : 'Left'}`]}
                >
                  <Box className={classes.messageContainer}>
                    <div style={{ display: 'flex', justifyContent: itsMe ? 'flex-end' : 'flex-start' }}>
                      {!itsMe ? avatar : null}
                      <div>
                        <Typography variant="caption" display="block" gutterBottom>
                          <b>{userName}</b>
                          <br />
                          <i>{message?.date ? moment(message.date).format('LLLL') : null}</i>
                        </Typography>
                      </div>
                      {itsMe ? avatar : null}
                    </div>
                    <Paper className={classes.messageText}>
                      <Typography variant="body2" display="block" gutterBottom>
                        <span dangerouslySetInnerHTML={{ __html: message.txt }} />
                      </Typography>
                      {message.footer}
                    </Paper>
                  </Box>
                </Box>
              )
            })}
          </div>
        </div>
      </MessagesContainer>

      <FieldContainer>
        <form
          className={classes.input}
          noValidate
          autoComplete="off"
          onSubmit={async e => {
            e.preventDefault()
            await onSubmit()
          }}
        >
          <TextField
            value={message}
            onChange={e => {
              setMessage(e.target.value)
            }}
            id="outlined-basic"
            label=""
            variant="outlined"
            rowsMax={4}
            InputProps={{
              startAdornment: (
                <EmojiPicker
                  onSelect={chosenEmoji => {
                    setMessage(`${message}${chosenEmoji.emoji}`)
                  }}
                >
                  {emojiPicker => (
                    <IconButton
                      aria-describedby={emojiPicker.id}
                      variant="contained"
                      color="primary"
                      onClick={emojiPicker.handleClick}
                      size="small"
                      style={{ marginRight: 10 }}
                    >
                      <SentimentVerySatisfiedIcon />
                    </IconButton>
                  )}
                </EmojiPicker>
              ),
            }}
            multiline
            fullWidth
          />
          <IconButton
            aria-label="delete"
            onClick={() => {
              onSubmit()
            }}
          >
            <SendIcon />
          </IconButton>
        </form>
      </FieldContainer>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  auth: state.auth || {},
  app: state.app || {},
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setChat: chat => {
    dispatch(
      updateChat({
        chat,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chat)
