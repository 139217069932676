import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  logText: {
    fontSize: 12,
    wordBreak: 'break-all',
  },
}))
export function LogText(props) {
  const { children } = props
  const classes = useStyles()

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography className={classes.logText}>{children}</Typography>
    </Paper>
  )
}

export default function Logs(props) {
  const { LOG_TYPE, logs } = props
  // const classes = useStyles()

  return (
    <Timeline align="alternate">
      {logs.map(log => {
        const logType = LOG_TYPE[log.type]
        return (
          <TimelineItem key={`timeline-log${log.SK}`}>
            <TimelineOppositeContent>
              <Typography variant="h6"  component="h6" color="textSecondary">
                {logType ? logType.title : null}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {logType ? logType.date(log) : null}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>{logType ? logType.icon(log) : null}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{logType ? logType.text(log) : null}</TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}
