import React from 'react'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

// import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'
import Loader from '../Loader'
import RestrictedPageBody from '../RestrictedPageBody'
import Logs, { LogText } from '../Logs'
import { useIntl } from 'react-intl'
import messages from './messages'

// const useStyles = makeStyles(theme => ({}))
const USER_LOGS = gql`
  query UserLogs {
    userLogs
  }
`

export default function UserActions(props) {
  // const { team } = props
  // const classes = useStyles()
  const { loading, error, data } = useQuery(USER_LOGS, {})
  const { formatMessage } = useIntl()

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`

  console.log('USER', 'LOGS', data)
  const { userLogs: logs = [] } = data

  const LOG_TYPE = {
    INVITATION: {
      title: 'Invitation',
      date: log => moment(log.at).format('LLLL'),
      text: log => (
        <LogText>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                { ...messages.INVITATION },
                {
                  breakline: '<br />',
                  email: `<b>${log.email}</b>`,
                  teamName: `<b>${log.team.nam}</b>`,
                },
              ),
            }}
          />
        </LogText>
      ),
      icon: () => <EmailIcon />,
    },
  }

  return (
    <RestrictedPageBody>
      <Typography variant="h6">{formatMessage({ ...messages.title })}</Typography>
      <Logs logs={logs} LOG_TYPE={LOG_TYPE} />
    </RestrictedPageBody>
  )
}
