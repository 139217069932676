import React from 'react'
import { connect } from 'react-redux'
import { updateApp } from '../../libs/redux/reducers/app'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { gql, useMutation } from '@apollo/client'
import QrReader from 'react-qr-reader'
import Loader from '../Loader'
import ItemImage from '../ItemImage'
import storeAPI from '../../libs/gql/storeAPI'
import { useIntl } from 'react-intl'
import messages from './messages'

const ACCEPT_ITEM_HANDOVER_REQUEST = gql`
  mutation AcceptItemHandoverRequest($itemId: String!, $isScan: Boolean) {
    acceptItemHandoverRequest(itemId: $itemId, isScan: $isScan)
  }
`

function Scan(props) {
  const { children, onClose/* , setFeedback */ } = props
  // const teamId = team.id
  const [open, setOpen] = React.useState(false)
  const [result, setResult] = React.useState()
  const { formatMessage } = useIntl()
  const inChat = false

  const [acceptItemHandoverRequest, { loading /* , error , data */ }] = useMutation(ACCEPT_ITEM_HANDOVER_REQUEST, {
    update: (store, { data: { acceptItemHandoverRequest } }) => {
      const { channelData = {} } = acceptItemHandoverRequest
      const item = {
        ...acceptItemHandoverRequest.channelData.item,
        itemHandoverAssignment: null,
      }
      storeAPI.getItem({
        store,
        data: {
          newItem: item,
          channelData,
          teamId: item.teamId,
        },
      })

      if (inChat) {
        storeAPI.getNewMessage({
          store,
          data: {
            ...acceptItemHandoverRequest,
            item,
            doUpdateUser: true,
            doUpdateChat: true,
          },
        })
      }

      storeAPI.editItem({
        store,
        data: {
          updatedItem: item,
          itemId: item.id,
          teamId: item.teamId,
        },
      })
      setOpen(false)
      onClose()
    },
  })

  if (loading) return <Loader loading />
  console.log('qr', result)

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      <Dialog
        open={open}
        fullScreen
        onClose={() => {
          setOpen(false)
          onClose()
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {result?.action === 'HANDOVER' ? formatMessage({ ...messages.handoverDialogTitle }) : null}
          {!result ? formatMessage({ ...messages.scanDialogTitle }) : null}
        </DialogTitle>
        <DialogContent>
          {result ? (
            <div>
              {result.action === 'HANDOVER' ? (
                <div>
                  <ItemImage src={result.item.img} />
                  <DialogContentText>
                    {formatMessage(
                      { ...messages.handoverDialogContent },
                      {
                        borrowerName: result.borrower.name,
                        teamName: result.team.name,
                        itemName: result.item.name,
                        b: text => <b>{text}</b>,
                      },
                    )}
                  </DialogContentText>
                  <DialogContentText style={{ marginTop: 20 }}>
                    {formatMessage({ ...messages.handoverDialogDoYouAccept })}
                  </DialogContentText>
                </div>
              ) : null}
            </div>
          ) : (
            <QrReader
              delay={300}
              onError={err => {
                console.error(err)
              }}
              onScan={data => {
                if (data) {
                  try {
                    setResult(JSON.parse(data))
                  } catch (e) {
                    console.log(e)
                  }
                }
              }}
              style={{ width: '100%' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
              onClose()
            }}
            color="primary"
          >
            {formatMessage({ ...messages.dialogCancelButton })}
          </Button>
          {result?.action === 'HANDOVER' ? (
            <Button
              onClick={async () => {
                try {
                  await acceptItemHandoverRequest({
                    variables: {
                      itemId: result.item.id,
                      isScan: true,
                    },
                  })
                  setOpen(false)
                  onClose()
                } catch (e) {
                  console.log(e)
                }
              }}
              color="primary"
            >
              {formatMessage({ ...messages.handoverDialogAcceptButton })}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispatch => ({
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Scan)
