import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import TeamForm from '../TeamForm'
import storeAPI from '../../libs/gql/storeAPI'

const EDIT_TEAM = gql`
  mutation UpdateTeam($id: String!, $name: String!) {
    updateTeam(id: $id, name: $name)
  }
`

export default function AddTeam(props) {
  const { team, children } = props
  const [open, setOpen] = React.useState(false)

  const [updateTeam, { loading, error /* , data */ }] = useMutation(EDIT_TEAM, {
    update: (store, { data: { updateTeam: updatedTeam } }) => {
      storeAPI.editTeam({
        store,
        data: {
          updatedTeam,
        },
      })
    },
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      <TeamForm team={team} formType="EDIT" onSubmit={updateTeam} open={open} setOpen={setOpen} />
    </React.Fragment>
  )
}
