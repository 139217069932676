import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Switch from '../Switch'
import { makeStyles } from '@material-ui/core/styles'
import UploadImage from '../UploadImage'
import useForm from '../../libs/hooks/useForm'
import { useIntl } from 'react-intl'
import messages from './messages'

const useStyles = makeStyles(theme => ({
  select: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
}))

export default function ItemForm(props) {
  const { item = {}, team, formType = 'ADD', onSubmit, open, setOpen } = props
  const teamId = team.id
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const texts = (() => {
    if (formType === 'ADD') {
      return {
        title: formatMessage({ ...messages.addTitle }),
        description: formatMessage({ ...messages.addDesc }, { teamName: team.name }),
        button: formatMessage({ ...messages.addSaveButton }),
      }
    }

    return {
      title: formatMessage({ ...messages.editTitle }),
      description: formatMessage({ ...messages.editDesc }, { itemName: item.name }),
      button: formatMessage({ ...messages.editSaveButton }),
    }
  })()

  const { form, fieldProps, setField, validate } = useForm({
    ownership: {
      value: item.ownership || 'COLLECTIVE',
      error: '',
      type: 'TEXT',
    },
    name: {
      value: item.name || '',
      error: '',
      required: true,
      type: 'TEXT',
    },
    description: {
      value: item.description || '',
      error: '',
      type: 'TEXT',
    },
    isAvailable: {
      value: item.isAvailable || false,
      error: '',
      type: 'CHECK',
    },
    image: {
      value: item.image || {},
      error: '',
      type: 'JSON',
    },
  })

  // console.log('LALA', form.isAvailable.value, form, item, item.isAvailable)

  const resetForm = () => {
    setField({ field: 'ownership', key: 'value', value: 'COLLECTIVE' })
    setField({ field: 'name', key: 'value', value: '' })
    setField({ field: 'description', key: 'value', value: '' })
    setField({ field: 'image', key: 'value', value: '' })
  }
  const onSave = async () => {
    try {
      const { hasErrors } = validate()
      // console.log('KOKO')
      if (!hasErrors) {
        const variables = (() => {
          const commonVariable = {
            teamId,
            ownership: form.ownership.value,
            name: form.name.value,
            description: form.description.value,
            isAvailable: form.isAvailable.value,
            image: form.image.value || {},
          }
          if (formType === 'ADD') {
            return commonVariable
          }

          return {
            itemId: item.id,
            ...commonVariable,
          }
        })()
        await onSubmit({
          variables,
        })
        setOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{texts.description}</DialogContentText>
        <form
          noValidate
          onSubmit={async e => {
            e.preventDefault()
            onSave()
          }}
        >
          {/* <FormControlLabel */}
          {/*   control={ */}
          {/*     <Switch */}
          {/*       name="isAvailable" */}
          {/*       {...fieldProps({ */}
          {/*         field: 'isAvailable', */}
          {/*         customError: null, */}
          {/*       })} */}
          {/*     /> */}
          {/*   } */}
          {/*   label={form.isAvailable.value ? 'is available' : 'is not available'} */}
          {/* /> */}
          <FormControl className={classes.select} variant="outlined" fullWidth>
            <InputLabel id="item-ownership">{formatMessage({ ...messages.labelItemOwnership })}</InputLabel>
            <Select
              labelId="item-ownership"
              inputProps={{ readOnly: formType !== 'ADD' && item.ownership === 'COLLECTIVE' ? true : false }}
              {...fieldProps({
                field: 'ownership',
                customError: null,
              })}
              label={formatMessage({ ...messages.labelItemOwnership })}
            >
              <MenuItem value="COLLECTIVE">{formatMessage({ ...messages.collective })}</MenuItem>
              <MenuItem value="PRIVATE">{formatMessage({ ...messages.private })}</MenuItem>
            </Select>
            <FormHelperText
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {form.ownership.value === 'COLLECTIVE' ? <SupervisedUserCircleIcon /> : null}
              {form.ownership.value === 'PRIVATE' ? <AccountCircleIcon /> : null}
              <div style={{ marginLeft: 5 }}>
                {formType === 'ADD' && form.ownership.value === 'COLLECTIVE'
                  ? formatMessage({ ...messages.addCollectiveDesc })
                  : null}
                {formType === 'ADD' && form.ownership.value === 'PRIVATE'
                  ? formatMessage({ ...messages.addPrivateDesc })
                  : null}
                {formType !== 'ADD' && form.ownership.value === 'COLLECTIVE'
                  ? formatMessage({ ...messages.editCollectiveDesc })
                  : null}
                {formType !== 'ADD' && form.ownership.value === 'PRIVATE'
                  ? formatMessage({ ...messages.editPrivateDesc })
                  : null}
              </div>
            </FormHelperText>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label={formatMessage({ ...messages.labelItemName })}
            name="name"
            autoComplete="name"
            autoFocus
            {...fieldProps({
              field: 'name',
              customError: null,
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label={formatMessage({ ...messages.labelItemName })}
            name="description"
            autoComplete="description"
            multiline
            {...fieldProps({
              field: 'description',
              customError: null,
            })}
          />
          <br />
          <br />
          <UploadImage
            initialImageData={item?.image}
            onChange={imageData => {
              setField({ field: 'image', key: 'value', value: imageData })
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm()
            setOpen(false)
          }}
          color="primary"
        >
          {formatMessage({ ...messages.cancel })}
        </Button>
        <Button
          onClick={async () => {
            onSave()
          }}
          color="primary"
        >
          {texts.button}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
