import { defineMessages } from 'react-intl'

export default defineMessages({
  addTitle: 'Create Item Handover Assignment',
  editTitle: 'Edit Item Handover Assignment',
  addDesc: `You are creating a handover assignment for {itemName}`,
  editDesc: 'You are editing a handover assignment for {itemName}',
  addSaveButton: 'Save',
  editSaveButton: 'Save',
  isRequired: 'It is required',
  labelReceiver: 'Receiver',
  labelDesc: 'Description',
  cancel: 'Cancel',
})
