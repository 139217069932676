import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Sign in',
  emailLabel: 'Email address',
  passwordLabel: 'Password',
  EMAIL_NOT_EXIST: 'Email does not exist',
  WRONG_PASSWORD: 'Wrong password',
  signinButton: 'Sign up',
  forgotPasswordLink: 'Forgot password?',
  signupLink: `Don't have an account? Sign Up`,
})
