import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import ItemForm from '../ItemForm'

const ADD_ITEM = gql`
  mutation AddItem($teamId: String!, $ownership: String!, $name: String!, $description: String!, $isAvailable: Boolean!, $image: JSON) {
    addItem(teamId: $teamId, ownership: $ownership, name: $name, description: $description, isAvailable: $isAvailable, image: $image)
  }
`

export default function AddItem(props) {
  const { children, team, update } = props
  // const teamId = team.id
  const [open, setOpen] = React.useState(false)

  const [addItem, { loading, error /* , data */ }] = useMutation(ADD_ITEM, {
    update,
    awaitRefetchQueries: true,
  })

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      {children({
        setOpen,
      })}
      <ItemForm type="ADD" team={team} onSubmit={addItem} open={open} setOpen={setOpen} />
    </React.Fragment>
  )
}
