import React from 'react'
import moment from 'moment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import BlockIcon from '@material-ui/icons/Block'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/Check'
import Divider from '@material-ui/core/Divider'
import ErrorMessage from '../ErrorMessage'
import AcceptInvitation from '../AcceptInvitation'
import RejectInvitation from '../RejectInvitation'
import storeAPI from '../../libs/gql/storeAPI'
import { useIntl } from 'react-intl'
import messages from './messages'

export default function TeamInvitations(props) {
  const { teamInvitations } = props
  const { formatMessage } = useIntl()

  return (
    <React.Fragment>
      <List>
        {teamInvitations.map((invitation, index) => {
          return (
            <React.Fragment key={`invitation_${invitation.id}`}>
              <ListItem>
                <ListItemText
                  primary={invitation.team.name}
                  secondary={
                    <React.Fragment>
                      {formatMessage(
                        { ...messages.collective },
                        {
                          date: `<b>${moment(invitation.addedAt).format('LLLL')}</b>`,
                          inviterName: `<b>${invitation.inviter.name}</b>`,
                        },
                      )}
                    </React.Fragment>
                  }
                />
                <ListItemText>
                  <RejectInvitation
                    update={(store, { data: { rejectInvitation: rejectInvitationRes } }) => {
                      storeAPI.rejectInvitation({
                        store,
                        data: {
                          invitation,
                          rejectInvitationRes,
                        },
                      })
                    }}
                  >
                    {({ rejectInvitation, rejectInvitationResponse }) => {
                      const { loading, error } = rejectInvitationResponse
                      if (error) return <ErrorMessage error={error} />

                      return (
                        <Button
                          style={{ marginRight: 5 }}
                          variant="outlined"
                          edge="end"
                          color="default"
                          startIcon={<BlockIcon />}
                          size="small"
                          onClick={() => {
                            rejectInvitation({
                              teamId: invitation.team.id,
                            })
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : formatMessage({ ...messages.reject })}
                        </Button>
                      )
                    }}
                  </RejectInvitation>
                  <AcceptInvitation
                    update={(store, { data: { acceptInvitation: acceptInvitationRes } }) => {
                      storeAPI.acceptInvitation({
                        store,
                        data: {
                          invitation,
                          acceptInvitationRes,
                        },
                      })
                    }}
                  >
                    {({ acceptInvitation, acceptInvitationResponse }) => {
                      const { loading, error } = acceptInvitationResponse
                      if (error) return <ErrorMessage error={error} />

                      return (
                        <Button
                          edge="end"
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<CheckIcon />}
                          onClick={() => {
                            acceptInvitation({
                              teamId: invitation.team.id,
                              teamName: invitation.team.name,
                              invited: {
                                by: invitation.inviter,
                                at: new Date(invitation.addedAt).getTime(),
                              },
                            })
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : formatMessage({ ...messages.accept })}
                        </Button>
                      )
                    }}
                  </AcceptInvitation>
                </ListItemText>
              </ListItem>
              {teamInvitations.length > index + 1 ? <Divider /> : null}
            </React.Fragment>
          )
        })}
      </List>
    </React.Fragment>
  )
}
