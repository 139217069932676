import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

import { Redirect } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { connect } from 'react-redux'
import auth from '../../libs/auth'
import useForm from '../../libs/hooks/useForm'
import getErrorMessage from '../../libs/getErrorMessage'
import Copyright from '../Copyright'
import { useIntl } from 'react-intl'
import messages from './messages'

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Login(props) {
  const { formatMessage } = useIntl()
  const [login, { data /* , loading */, error }] = useMutation(LOGIN)
  const { form, fieldProps /* , setField */, validate } = useForm({
    email: {
      value: '',
      error: '',
      required: true,
    },
    password: {
      value: '',
      error: '',
      required: true,
    },
  })
  React.useEffect(() => {
    if (data?.login) {
      props.login({
        tokenId: data.login.token,
        user: data.login.user,
      })
    }
  }, [props, data])
  const classes = useStyles()

  if (props.auth.user.id) {
    return <Redirect to="/dashboard" />
  }

  const loginError = (() => {
    if (error) {
      return getErrorMessage(error)
    }
    return
  })()

  console.log('ERROR', loginError, loginError?.MESSAGE_CODE === 'EMAIL_NOT_EXIST' && 'Email does not exist')

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {formatMessage({ ...messages.title })}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={async e => {
            e.preventDefault()
            const { hasErrors } = validate()
            if (!hasErrors) {
              try {
                await login({
                  variables: {
                    email: form.email.value,
                    password: form.password.value,
                    // email: '1',
                    // password: '123456',
                  },
                })
              } catch (err) {
                console.log('login error', err)
              }
            }
          }}
        >
          {/* <Paper square> */}
          {/*   <Tabs */}
          {/*     value={0} */}
          {/*     indicatorColor="primary" */}
          {/*     textColor="primary" */}
          {/*     onChange={() => {}} */}
          {/*     aria-label="disabled tabs example"> */}
          {/*     <Tab label="Passwordless" /> */}
          {/*     <Tab label="With password" /> */}
          {/*   </Tabs> */}
          {/* </Paper> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={formatMessage({ ...messages.emailLabel })}
            name="email"
            autoComplete="email"
            autoFocus
            {...fieldProps({
              field: 'email',
              customError: loginError === 'EMAIL_NOT_EXIST' && formatMessage({ ...messages.EMAIL_NOT_EXIST }),
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={formatMessage({ ...messages.passwordLabel })}
            type="password"
            id="password"
            autoComplete="current-password"
            {...fieldProps({
              field: 'password',
              customError: loginError === 'WRONG_PASSWORD' && formatMessage({ ...messages.WRONG_PASSWORD }),
            })}
          />
          {/* <FormControlLabel */}
          {/*   control={<Checkbox value="remember" color="primary" />} */}
          {/*   label="Remember me" */}
          {/* /> */}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {formatMessage({ ...messages.signinButton })}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#/forget-password" variant="body2">
                {formatMessage({ ...messages.forgotPasswordLink })}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#/register" variant="body2">
                {formatMessage({ ...messages.signupLink })}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: ({ tokenId, user }) => {
    // console.log({tokenId, user});
    auth.login({ tokenId, user })
    // dispatch(logout())
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login)
