import PubSub from 'pubsub-js'

const updateTeam = ({ store, data: { updatedItem, teamId } }) => {
  PubSub.publish('itemHasBeenUpdated', { updatedItem })
}

const itemHasBeenUpdated = props => {
  try {
    updateTeam(props)
  } catch (e) {
    console.log(e)
  }
}

export default itemHasBeenUpdated
