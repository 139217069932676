import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import RestrictedPageBody from '../RestrictedPageBody'
// import Loader from '../Loader'
import AddTeam from '../AddTeam'
import TeamInvitations from '../TeamInvitations'
import ChatChannels from '../ChatChannels'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import WebAssetIcon from '@material-ui/icons/WebAsset'
import PeopleIcon from '@material-ui/icons/People'
import ItemDetails from '../ItemDetails'
import { useIntl } from 'react-intl'
import messages from './messages'

// import USER_QUERY from '../../libs/gql/USER_QUERY'
import storeAPI from '../../libs/gql/storeAPI'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
    margin: '30px 0 10px',
  },
  pos: {
    marginBottom: 12,
  },
  listToot: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  autoComplete: {
    margin: '10px 0',
  },
}))

function Dashboard(props) {
  const {
    userMeta: { teams = [], teamInvitations = [], items = [] },
    userMeta,
    app,
  } = props

  const classes = useStyles()
  const history = useHistory()
  const { formatMessage } = useIntl()

  const userItemsIds = items.map(item => item.id)
  const [selectedItem, setSelectedItem] = React.useState(null)
  return (
    <RestrictedPageBody>
      {userMeta.chatChannels.length ? (
        <React.Fragment>
          <Typography variant="h6" className={classes.title}>
            {formatMessage({ ...messages.chatTitle })}
          </Typography>
          <ChatChannels
            userMeta={userMeta}
            app={app}
            onClick={data => {
              setSelectedItem({
                item: data.item,
                team: data.team,
              })
            }}
          />
          {selectedItem ? (
            <ItemDetails
              borrower={userItemsIds.includes(selectedItem.item.id)}
              {...selectedItem}
              activeTab="chat"
              setClose={() => setSelectedItem(null)}
            />
          ) : null}
        </React.Fragment>
      ) : null}
      {teamInvitations.length ? (
        <React.Fragment>
          <Typography variant="h6" className={classes.title}>
            {formatMessage({ ...messages.invitations })}
          </Typography>
          <Card className={classes.root} style={{ marginBottom: 10 }}>
            <CardContent>
              <TeamInvitations teamInvitations={teamInvitations} />
            </CardContent>
          </Card>
        </React.Fragment>
      ) : null}
      <Typography variant="h6" className={classes.title}>
        {formatMessage({ ...messages.teamsTitle })}
      </Typography>
      <Card className={classes.root}>
        <CardContent>
          {teams.map(team => (
            <Button
              size="small"
              key={team.id}
              style={{ width: 200, height: 100, margin: 5, display: 'inline-block' }}
              variant="outlined"
              onClick={() => {
                history.push(`/team/${team.id}`)
              }}
            >
              <div>{team.name}</div>
              <PeopleIcon fontSize="small" /> {team.numberOfMembers} <WebAssetIcon fontSize="small" />{' '}
              {team.numberOfItems}
            </Button>
          ))}
          <AddTeam
            update={(store, { data: { addTeam: newTeam } }) => {
              storeAPI.addTeam({ store, data: { newTeam } })
            }}
          >
            {(addTeam) => (
              <Button
                color="secondary"
                onClick={() => {
                  addTeam.setOpen(true)
                }}
              >
                {formatMessage({ ...messages.createTeamButton })}
              </Button>
            )}
          </AddTeam>
        </CardContent>
      </Card>
    </RestrictedPageBody>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app || {},
})

const mapDispatchToProps = null
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard)
