import React from 'react'
import * as colors from '@material-ui/core/colors'

export default function Badge(props) {
  const { content = '', color = 'grey', hue = '500', size = 'default' } = props

  // console.log('GREEN', colors.green)
  // colors[color][hue]
  const bgColor = (() => {
    if (!colors[color] || !colors[color][hue]) {
      return colors.grey['500']
    }
    return colors[color][hue]
  })()
  const textColor = (() => {
    if (parseInt(hue) <= 300) {
      return '#FFFFFF'
    }

    return '#222222'
  })()
  const borderRadius = content.toString().length <= 1 ? '100%' : 5
  const padding = (() => {
    const isEmpty = !content?.toString()
    if (size === 'small') {
      return !isEmpty ? '2px 6px' : '0px 7px'
    }

    return !isEmpty ? '2px 6px' : '2px 9px'
  })()

  return (
    <span
      style={{
        borderRadius,
        padding,
        fontSize: 12,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {content}
    </span>
  )
}
