import ITEM_MESSAGES from '../ITEM_MESSAGES'
import USER_QUERY from '../USER_QUERY'

function updateChat({ store, data: { item, newMessage, channelData } }) {
  const queryProps = {
    query: ITEM_MESSAGES,
    variables: {
      id: item.id,
    },
  }
  const data = store.readQuery(queryProps)
  console.log('CHAT', 'newMessage', newMessage, data)
  const messages = data?.itemMessages?.messages || []
  const newData = {
    ...data,
    itemMessages: {
      messages: [...messages, newMessage],
    },
  }
  // console.log('NEW_ITEM', data, newData)
  store.writeQuery({
    ...queryProps,
    data: newData,
  })
}
function updateUserChannels({ store, data: { item, newMessage, channelData } }) {
  const queryProps = { query: USER_QUERY }
  const data = store.readQuery(queryProps)
  const newChatChannels = (() => {
    let hasChanged = false
    const newData = data.userMeta.chatChannels.map(c => {
      console.log('updateUserChannels', c.item.id, channelData.item.id)
      if (c.topic === 'ITEM' && c.item.id === channelData.item.id) {
        hasChanged = true
        return channelData
      }
      return c
    })
    if (!hasChanged) {
      return [...newData, channelData]
    }

    return newData
  })()
  const newData = {
    ...data,
    userMeta: {
      ...data.userMeta,
      chatChannels: newChatChannels,
    },
  }
  // console.log('NEW_ITEM', data, newData)
  store.writeQuery({
    ...queryProps,
    data: newData,
  })
}
const getNewMessage = props => {
  const {
    data: { doUpdateUser, doUpdateChat, newMessage },
  } = props
  console.log('getNewMessage', props.data)
  const itemId = newMessage.PK.replace('item_', '')
  const item = { id: itemId }
  if (doUpdateUser) {
    updateUserChannels({ ...props, data: { ...props.data, item } })
  }
  if (doUpdateChat) {
    try {
      updateChat({ ...props, data: { ...props.data, item } })
    } catch (e) {
        console.log(e)
    }
  }
}

export default getNewMessage
