import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/Image'
import { grey } from '@material-ui/core/colors'

const height = 100
const useStyles = makeStyles({
  noImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey['100'],
    height,
    cursor: 'pointer',
  },
  img: {
    height,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey['100'],
    cursor: 'pointer',
  },
})

export default function ItemImage(props) {
  const { src = '', onClick } = props
  const classes = useStyles()

  return src ? (
    <div className={classes.imgContainer} role="button" onClick={onClick}>
      <div style={{ height: 100 }}>
        <img className={classes.img} src={src} alt="" />
      </div>
    </div>
  ) : (
    <div className={classes.noImage} role="button" onClick={onClick}>
      <span>
        <ImageIcon style={{ color: grey[500] }} />
      </span>
    </div>
  )
}
