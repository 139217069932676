import authLib from '../../auth'

const PREFIX = 'AUTH'

export const AUTH_UPDATE_USER = `${PREFIX}_UPDATE_USER`
export const login = (payload = {}) => ({
  type: AUTH_UPDATE_USER,
  payload,
})
export const logout = () => ({
  type: AUTH_UPDATE_USER,
  payload: {
    user: {},
  },
})
export const updateAuthUser = (payload = {}) => ({
  type: AUTH_UPDATE_USER,
  payload,
})
const INITIAL_STATE = {
  user: authLib.getUser() || {},
}
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...action.payload.user,
        },
      }
    default:
      return state
  }
}
