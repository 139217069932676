import { defineMessages } from 'react-intl'

export default defineMessages({
  me: 'me',
  i: 'I',
  EDIT_HANDOVER_ASSIGNMENT: `{userAvatar}{userName} changed the assignment. {receiverAvatar}{receiverName} will get {itemName}. {breakLine}{breakLine}{userAvatar}{userName} wrote: {breakLine}"{message}"`,
  NEW_HANDOVER_ASSIGNMENT: `{userAvatar}{userName} created a new assignment. {receiverAvatar}{receiverName} will get {itemName}. {breakLine}{breakLine}{userAvatar}{userName} wrote: {breakLine}"{message}"`,
  SEND_HANDOVER_REQUEST: `{userAvatar}{userName} sent a handover request to {receiverAvatar}{receiverName} for {itemName}.`,
  ITEM_HANDOVER: `New Borrower{breakLine}{borrowerAvatar}{borrowerName} handed over {itemName} to {userAvatar}{userName}`,
  acceptButton: 'Accept the request',
})
