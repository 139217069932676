import React from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'
import QRCode from 'qrcode.react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'

// import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Divider from '@material-ui/core/Divider'

// import MBadge from '@material-ui/core/Badge'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../TabPanel'
import HomeIcon from '@material-ui/icons/Home'
import ChatIcon from '@material-ui/icons/Chat'
import HistoryIcon from '@material-ui/icons/History'

// import ErrorMessage from '../ErrorMessage'
import ItemInfo from '../ItemInfo'
import ItemChat from '../ItemChat'
import ItemLogs from '../ItemLogs'
import Loader from '../Loader'
// import Badge from '../Badge'
import EditItem from '../EditItem'
import { green, red } from '@material-ui/core/colors'
// import Chat, { getChannelName } from '../Chat'
import ItemImage from '../ItemImage'
// import RequestItem from '../RequestItem'
import CreateItemHandoverAssignment from '../CreateItemHandoverAssignment'
import EditItemHandoverAssignment from '../EditItemHandoverAssignment'
import SendItemHandoverRequest from '../SendItemHandoverRequest'
import ITEM_DETAILS from '../../libs/gql/ITEM_DETAILS'
import storeAPI from '../../libs/gql/storeAPI'
import { useIntl } from 'react-intl'
import messages from './messages'
import './style.css'

const useStyles = makeStyles(theme => ({
  media: {
    height: 140,
  },
  isAvailable: {
    color: green['500'],
    marginLeft: 5,
  },
  isNotAvailable: {
    color: red['500'],
    marginLeft: 5,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabs: {
    flexGrow: 1,
  },
  tab: {
    fontSize: 9,
    padding: 2,
  },
}))

const tabs = ['home', 'chat', 'logs']

function ItemDetails(props) {
  const {
    auth: { user },
    setClose,
    team,
    item: { id: itemId },
    activeTab,
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { loading, error, data } = useQuery(ITEM_DETAILS, {
    variables: {
      id: itemId,
    },
    fetchPolicy: 'network-only',
  })
  const [tab, setTab] = React.useState(activeTab ? tabs.indexOf(activeTab) : 0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const moreActionOpen = Boolean(anchorEl)

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`

  const { item } = data

  const isBorrower = user.id === item.borrower.id
  const isOwner = user.id === item.owner.id
  const isPrivate = item.ownership === 'PRIVATE'
  console.log('ItemDetails', item, 'isBorrower', isBorrower, 'isOwner', isOwner, 'isPrivate', isPrivate)
  const qrCodeValue = {
    item: {
      id: item.id,
      name: item.name,
      img: item?.image?.link,
    },
    team: {
      id: team.id,
      name: team.name,
    },
    borrower: {
      id: item.borrower.id,
      name: item.borrower.name,
    },
    action: 'HANDOVER',
  }

  const isMine = user.id === item.borrower.id

  return (
    <Dialog
      open
      onClose={() => {
        setClose()
      }}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">
        {item.name}

        <Tabs
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue)
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab className={classes.tab} label="home" icon={<HomeIcon />} />
          <Tab className={classes.tab} label="Chat" icon={<ChatIcon />} />
          <Tab className={classes.tab} label="logs" icon={<HistoryIcon />} />
        </Tabs>
      </DialogTitle>
      <TabPanel value={tab} index={0}>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div>
            <ItemImage src={item.image.link} />
            {/* <Badge size="small" color={item.isAvailable ? 'green' : 'red'} /> */}
            {/* <span className={item.isAvailable ? classes.isAvailable : classes.isNotAvailable}> */}
            {/*   {item.isAvailable ? 'it is available' : 'it is not available'} */}
            {/* </span> */}

            <ItemInfo team={team} item={item} isMine={isMine} showDesc />
            <Grid container>
              {isBorrower ? (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <QRCode style={{ margin: '40px 0' }} size={200} value={JSON.stringify(qrCodeValue)} />
                </Grid>
              ) : null}
            </Grid>
            {/* <Chat channel={getChannelName({ name: item.name, id: item.SK.replace(/ /g, '-') })} /> */}
          </div>
        </DialogContent>
      </TabPanel>

      <TabPanel value={tab} index={1} style={{ width: '100%' }}>
        <ItemChat
          team={team}
          item={item}
          user={user}
          MessagesContainer={DialogContent}
          FieldContainer={DialogActions}
        />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <DialogContent>
          <ItemLogs item={item} />
        </DialogContent>
      </TabPanel>

      <Divider />
      <DialogActions>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div style={{ flex: '1 1 100%' }}>
            <Button
              onClick={() => {
                setClose()
              }}
            >
              {formatMessage({ ...messages.closeButton })}
            </Button>
          </div>
          {isBorrower ? (
            <React.Fragment>
              <Button
                endIcon={<MoreVertIcon />}
                onClick={event => {
                  setAnchorEl(event.currentTarget)
                }}
              >
                {formatMessage({ ...messages.moreButton })}
              </Button>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={moreActionOpen}
                onClose={() => {
                  setAnchorEl(null)
                }}
                PaperProps={{
                  style: {
                    // maxHeight: ITEM_HEIGHT * 4.5,
                    // width: '20ch',
                  },
                }}
              >
                {isBorrower && !item.itemHandoverAssignment ? (
                  <CreateItemHandoverAssignment item={item} team={team} user={user}>
                    {createItemHandoverAssignmentProps => (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null)
                          createItemHandoverAssignmentProps.setOpen(true)
                        }}
                      >
                        {formatMessage({ ...messages.createHandoverAssignment })}
                      </MenuItem>
                    )}
                  </CreateItemHandoverAssignment>
                ) : null}
                {isBorrower && item.itemHandoverAssignment ? (
                  <EditItemHandoverAssignment item={item} team={team} user={user}>
                    {editItemHandoverAssignmentProps => (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null)
                          editItemHandoverAssignmentProps.setOpen(true)
                        }}
                      >
                        {formatMessage({ ...messages.editHandoverAssignment })}
                      </MenuItem>
                    )}
                  </EditItemHandoverAssignment>
                ) : null}

                {isBorrower && item.itemHandoverAssignment ? (
                  <SendItemHandoverRequest item={item} team={team} user={user}>
                    {({ sendItemHandoverRequest }) => (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null)
                          sendItemHandoverRequest()
                        }}
                      >
                        {formatMessage({ ...messages.sendAHandoverRequest })}
                      </MenuItem>
                    )}
                  </SendItemHandoverRequest>
                ) : null}

                {(isBorrower && !isPrivate) || (isBorrower && isOwner && isPrivate) ? (
                  <EditItem
                    team={team}
                    item={item}
                    update={(store, props) => {
                      const {
                        data: { editItem: updatedItem },
                      } = props

                      storeAPI.editItem({ store, data: { updatedItem, itemId, teamId: team.id } })
                    }}
                  >
                    {editItemProps => (
                      <MenuItem
                        size="small"
                        color="primary"
                        onClick={() => {
                          setAnchorEl(null)
                          editItemProps.setOpen(true)
                        }}
                      >
                        {formatMessage({ ...messages.editButton })}
                      </MenuItem>
                    )}
                  </EditItem>
                ) : null}
              </Menu>
            </React.Fragment>
          ) : null}
        </div>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  auth: state.auth || {},
})

const mapDispatchToProps = null
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDetails)
