import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
// import { gql, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
// import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Drawer from '@material-ui/core/Drawer'
import CropFreeIcon from '@material-ui/icons/CropFree'
import Avatar from '@material-ui/core/Avatar'
import { useIntl } from 'react-intl'
import messages from './messages'
import getAvatarUrl from '../../libs/avatarUrl'

import Scan from '../Scan'
import auth from '../../libs/auth'
import logo from './logo.svg'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

// const LOGOUT = gql`
//   mutation Logout {
//     logout
//   }
// `

function Header(props) {
  const {
    auth: { user },
    logout,
  } = props
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const history = useHistory()

  // const [remoteLogout #<{(| , {data, loading,  error } |)}>#] = useMutation(LOGOUT)
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setOpen(open)
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      {user.id ? (
        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          <List>
            <ListItem
              button
              onClick={() => {
                history.push(`/dashboard`)
              }}
            >
              <ListItemText primary={formatMessage({ ...messages.dashboard })} />
            </ListItem>
            <Scan onClose={() => setOpen(false)}>
              {scan => (
                <ListItem
                  button
                  onClick={() => {
                    scan.setOpen(true)
                  }}
                >
                  <ListItemIcon>
                    <CropFreeIcon />
                  </ListItemIcon>
                  <ListItemText primary={formatMessage({ ...messages.scan })} />
                </ListItem>
              )}
            </Scan>
            {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
            {/*   <ListItem button key={text}> */}
            {/*     <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/*     <ListItemText primary={text} /> */}
            {/*   </ListItem> */}
            {/* ))} */}
          </List>
        </Drawer>
      ) : null}

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                setOpen(true)
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}
              className={classes.title}
              onClick={() => {
                history.push(user.id ? `/dashboard` : '/')
              }}
            >
              <img src={logo} style={{ transform: 'scale(0.5)', marginLeft: '-50px' }} alt="logo" />
            </Typography>
            <div>
              {user.id ? (
                <React.Fragment>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Typography variant="caption" display="inline">
                      {user.name}
                    </Typography>

                    <Avatar
                      alt={user.name}
                      src={getAvatarUrl({ seed: user.id })}
                      style={{ width: 30, height: 30, background: '#ccc', marginLeft: 5 }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null)
                        history.push(`/my-actions`)
                      }}
                    >
                      {formatMessage({ ...messages.myActions })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null)
                        history.push(`/my-account`)
                      }}
                    >
                      {formatMessage({ ...messages.myAccount })}
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        setAnchorEl(null)
                        try {
                          // await remoteLogout()
                          logout()
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                    >
                      {formatMessage({ ...messages.logout })}
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              ) : (
                <MenuItem onClick={() => history.push(`/login`)}>{formatMessage({ ...messages.login })} </MenuItem>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => {
    auth.logout()
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
