import React from 'react'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

// import { makeStyles } from '@material-ui/core/styles'
// import SyncAltIcon from '@material-ui/icons/SyncAlt'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import EmailIcon from '@material-ui/icons/Email'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import Logs, { LogText } from '../Logs'
import Loader from '../Loader'
import { useIntl } from 'react-intl'
import messages from './messages'

const TEAM_LOGS = gql`
  query TeamLogs($id: String!) {
    teamLogs(id: $id)
  }
`

// const useStyles = makeStyles(theme => ({
//   paper: {
//     padding: '6px 16px',
//   },
//   secondaryTail: {
//     backgroundColor: theme.palette.secondary.main,
//   },
// }))

export default function ItemLogs(props) {
  const { team } = props
  // const classes = useStyles()
  const { formatMessage } = useIntl()
  const { loading, error, data } = useQuery(TEAM_LOGS, {
    variables: {
      id: team.id,
    },
  })

  if (loading) return <Loader loading />
  if (error) return `Error! ${error.message}`

  console.log('TEAM', 'LOGS', data)
  const { teamLogs: logs = [] } = data

  const LOG_TYPE = {
    TEAM_CREATION: {
      title: 'Added',
      date: log => moment(log.at).format('LLLL'),
      text: log => (
        <LogText>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                { ...messages.TEAM_CREATION },
                {
                  creatorName: `<b>${log.creator.name}</b>`,
                },
              ),
            }}
          />
        </LogText>
      ),
      icon: () => <GroupAddIcon />,
    },
    INVITATION: {
      title: 'Invitation',
      date: log => moment(log.at).format('LLLL'),
      text: log => (
        <LogText>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                { ...messages.INVITATION },
                {
                  invitedName: `<b>${log.invited.nam}</b>`,
                  email: `<b>${log.email}</b>`,
                  teamName: `<b>${log.team.nam}</b>`,
                  breakline: '<br />',
                },
              ),
            }}
          />
        </LogText>
      ),
      icon: () => <EmailIcon />,
    },
    REJECTED_INVITATION: {
      title: 'Rejected Invitation',
      date: log => moment(log.at).format('LLLL'),
      text: log => (
        <LogText>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                { ...messages.REJECTED_INVITATION },
                {
                  name: `<b>${log.user.name}</b>`,
                },
              ),
            }}
          />
        </LogText>
      ),
      icon: () => <PersonAddDisabledIcon />,
    },
    ACCEPTED_INVITATION: {
      title: 'Accepted Invitation',
      date: log => moment(log.at).format('LLLL'),
      text: log => (
        <LogText>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                { ...messages.ACCEPTED_INVITATION },
                {
                  name: `<b>${log.newMember.name}</b>`,
                },
              ),
            }}
          />
        </LogText>
      ),
      icon: () => <PersonAddIcon />,
    },
  }

  return <Logs logs={logs} LOG_TYPE={LOG_TYPE} />
}
