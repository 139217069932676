import React from 'react'

export default function useForm(initialData) {
  const [form, setForm] = React.useState(initialData)

  const setField = ({ field, key, value }) => {
    const allowedKeys = ['value', 'error']
    if (!allowedKeys.includes(key)) {
      throw new Error(`useForm: key must be ${allowedKeys.join(', ')}`)
    }

    setForm({
      ...form,
      [field]: {
        ...form[field],
        [key]: value,
      },
    })
  }

  const fieldProps = ({ field, customError }) => {
    const { type } = form[field]
    const moreProps = (() => {
      if (type === 'CHECK') {
        return {
          checked: Boolean(form[field].value),
          onChange: e => {
            setField({
              field,
              key: 'value',
              value: e.target.checked,
            })
          },
        }
      }
      const error = Boolean(form[field].error || customError)
      return {
        value: form[field].value,
        onChange: (e, newValue) => {
          const value = (() => {
            if (type === 'JSON') {
              return newValue
            }
            return e.target.value
          })()
          setField({
            field,
            key: 'value',
            value,
          })
        },
        error: type === 'JSON' ? error.toString() : error,
        helperText: type !== 'JSON' ? form[field].error || customError : undefined,
      }
    })()
    return {
      ...moreProps,
    }
  }

  const validate = () => {
    let hasErrors = false
    Object.keys(form).forEach(field => {
      const { validation = [], required, value, type = 'TEXT' } = form[field]
      if (required) {
        const isEmpty = []
        isEmpty.push(type === 'TEXT' && (!value || !value.trim()))
        isEmpty.push(type === 'JSON' && !value)
        if (isEmpty.some(r => r)) {
          setField({
            field,
            key: 'error',
            value: 'It is required',
          })

          hasErrors = true
        }
      }
      if (validation.length) {
        validation.forEach(v => {
          if (!v.regExp.test(value)) {
            setField({
              field,
              key: 'error',
              value: v.message,
            })

            hasErrors = true
          }
        })
      }
    })
    return {
      hasErrors,
    }
  }

  return {
    form,
    fieldProps,
    setField,
    validate,
  }
}
