import { defineMessages } from 'react-intl'

export default defineMessages({
  closeButton: 'Close',
  moreButton: 'More',
  editButton: 'Edit',
  createHandoverAssignment: 'Create handover assignment',
  editHandoverAssignment: 'Edit handover assignment',
  sendAHandoverRequest: 'Send a handover request',
})
