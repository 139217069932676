import React from 'react'
import moment from 'moment'
// import { useQuery } from '@apollo/client'
// import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import CommentIcon from '@material-ui/icons/Comment'
import Button from '@material-ui/core/Button'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { useIntl } from 'react-intl'
import messages from './messages'
import AcceptItemHandoverRequest from '../AcceptItemHandoverRequest'
// import Badge from '../Badge'

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
  },
  listItem: {
    padding: 20,
  },
}))

export default function ChatChannels(props) {
  const { userMeta, app, team, onClick } = props
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const { chatChannels = [], items = [], teams = [] } = userMeta

  console.log('ChatChannels', chatChannels)
  const userItemsIds = items.map(item => item.id)
  const teamItemsChatChannels = chatChannels
    .filter(chatChannel => {
      if (chatChannel.topic === 'ITEM') {
        return team ? team.id === chatChannel.item.teamId : true
      }
      return false
    })
    .map(chatChannel => {
      const itemId = chatChannel.item.id
      const team = teams.find(t => t.id === chatChannel.item.teamId)
      return {
        ...chatChannel,
        team,
        isMine: userItemsIds.includes(itemId),
        hasMessage: new Date(chatChannel.updatedAt).getTime() > (app.chat[`chat_item_${chatChannel.item.id}`] || 0),
        type: 'item',
      }
    })
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
  const sortedChatChannels = teamItemsChatChannels.sort((a, b) => b.updatedAt - a.updatedAt)

  console.log('TeamChat', sortedChatChannels)

  // console.log('chatChannels', sortedChatChannels, chatChannels, teamItemsChatChannels)
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <List>
          {sortedChatChannels.map((chatChannel, index) => (
            <React.Fragment key={chatChannel.id}>
              <ListItem
                className={classes.listItem}
                role={undefined}
                dense
                button
                onClick={() => {
                  onClick({
                    team: chatChannel.team,
                    item: chatChannel.item,
                  })
                  // history.push(`/team/${chatChannel.team.id}/item/${chatChannel.item.id}/tab/chat`)
                }}
              >
                <div style={{ marginRight: 30 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <span>{chatChannel.item.name}</span>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                          <CalendarTodayIcon fontSize="small" />
                          <span style={{ marginLeft: 5 }}>
                            {chatChannel.updatedAt ? moment(chatChannel.updatedAt).format('LLLL') : null}
                          </span>
                        </span>
                        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                          {chatChannel.item.ownership === 'COLLECTIVE' ? (
                            <SupervisedUserCircleIcon color="action" fontSize="small" />
                          ) : null}
                          {chatChannel.item.ownership === 'PRIVATE' ? (
                            <AccountCircleIcon color="action" fontSize="small" />
                          ) : null}

                          <span style={{ marginLeft: 5 }}>
                            {chatChannel.item.ownership === 'COLLECTIVE' ? chatChannel.team.name : null}
                            {chatChannel.item.ownership === 'PRIVATE' ? chatChannel.item.owner.name : null}
                          </span>
                        </span>
                        <span style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}>
                          <AssignmentIndIcon color={chatChannel.isMine ? 'primary' : 'action'} fontSize="small" />
                          <span style={{ marginLeft: 5 }}>{chatChannel.item.borrower.name}</span>
                        </span>
                      </React.Fragment>
                    }
                  />
                  {chatChannel.item.itemHandoverAssignment ? (
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {formatMessage(
                            { ...messages.itemHandoverAssignment },
                            {
                              borrowerName: chatChannel.item.borrower.name,
                              receiverName: chatChannel.item.itemHandoverAssignment.receiver.name,

                              b: (...text) => <b>{text}</b>,
                            },
                          )}
                        </React.Fragment>
                      }
                      secondary={chatChannel.item.itemHandoverAssignment.description}
                    />
                  ) : null}
                </div>
                {chatChannel.hasMessage ? (
                  <ListItemSecondaryAction>
                    <CommentIcon color="secondary" />
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>

              <ListItem>
                {chatChannel.item?.itemHandoverAssignment?.sentHandoverRequestAt ? (
                  <AcceptItemHandoverRequest item={chatChannel.item}>
                    {({ acceptItemHandoverRequest }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={() => {
                          try {
                            acceptItemHandoverRequest({
                              itemId: chatChannel.item.id,
                            })
                          } catch (e) {
                            console.log('acceptItemHandoverRequest', e)
                          }
                        }}
                      >
                        {formatMessage({ ...messages.acceptRequestButton })}
                      </Button>
                    )}
                  </AcceptItemHandoverRequest>
                ) : null}
              </ListItem>
              {sortedChatChannels.length - 1 > index ? <Divider /> : null}
            </React.Fragment>
          ))}
        </List>
      </Card>
    </React.Fragment>
  )
}
