import React from 'react'
import { gql, useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import useForm from '../../libs/hooks/useForm'
import { useIntl } from 'react-intl'
import messages from './messages'

const TEAM_MEMBERS = gql`
  query TeamMembers($teamId: String!) {
    teamMembers(teamId: $teamId)
  }
`
const useStyles = makeStyles(theme => ({
  autoComplete: {
    margin: '10px 0',
  },
  select: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
}))

export default function ItemHandoverAssignmentForm(props) {
  const { user, item = {}, team, formType = 'ADD', onSubmit, open, setOpen } = props

  const { formatMessage } = useIntl()
  const classes = useStyles()

  const { data, loading, error } = useQuery(TEAM_MEMBERS, {
    variables: {
      teamId: team.id,
    },
    fetchPolicy: 'network-only',
  })

  const { form, fieldProps, setField, validate } = useForm({
    receiver: {
      value: null,
      error: '',
      type: 'JSON',
      required: true,
    },
    description: {
      value: item?.itemHandoverAssignment?.description || '',
      error: '',
      type: 'TEXT',
    },
  })

  React.useEffect(() => {
    if (data?.teamMembers && item?.itemHandoverAssignment?.receiver?.id) {
      setField({
        field: 'receiver',
        key: 'value',
        value: data.teamMembers.find(member => member.id === item.itemHandoverAssignment.receiver.id),
      })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader loading />
  if (error) return <ErrorMessage error={error} />

  const { teamMembers } = data

  const texts = (() => {
    if (formType === 'ADD') {
      return {
        title: formatMessage({ ...messages.addTitle }),
        description: formatMessage({ ...messages.addDesc }, { itemName: item.name }),
        button: formatMessage({ ...messages.addSaveButton }),
      }
    }

    return {
        title: formatMessage({ ...messages.editTitle }),
        description: formatMessage({ ...messages.editDesc }, { itemName: item.name }),
        button: formatMessage({ ...messages.editSaveButton }),
    }
  })()

  // console.log('LALA', form.isAvailable.value, form, item, item.isAvailable)

  const resetForm = () => {
    setField({ field: 'receiver', key: 'value', value: '' })
    setField({ field: 'description', key: 'value', value: '' })
  }
  const hasFormChanged = () => {
    if (!item.itemHandoverAssignment) {
      return true
    }
    return (
      item?.itemHandoverAssignment?.receiver?.id !== form.receiver.value?.id ||
      form.description?.value?.trim() !== item?.itemHandoverAssignment?.description
    )
  }
  const onSave = async () => {
    if (!hasFormChanged()) {
      return
    }
    try {
      const { hasErrors } = validate()
      if (!hasErrors) {
        const variables = (() => {
          const commonVariable = {
            itemId: item.id,
            receiverId: form.receiver.value.id,
            description: form.description.value,
          }
          if (formType === 'ADD') {
            return commonVariable
          }

          return {
            id: item?.itemHandoverAssignment?.id.toString(),
            ...commonVariable,
          }
        })()
        await onSubmit({
          variables,
        })
        setOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{texts.description}</DialogContentText>
        <form
          noValidate
          onSubmit={async e => {
            e.preventDefault()
            onSave()
          }}
        >
          <Autocomplete
            id="search-team-members"
            options={teamMembers
              .slice()
              .filter(member => member.id !== user.id)
              .sort((a, b) => b.name - a.name)}
            getOptionLabel={option => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            fullWidth
            {...fieldProps({
              field: 'receiver',
              customError: null,
            })}
            renderInput={params => (
              <TextField
                {...params}
                error={form.receiver.error}
                label={formatMessage({ ...messages.labelReceiver })}
                variant="outlined"
              />
            )}
            className={classes.autoComplete}
          />
          {form.receiver.error ? (
            <Typography color="error" variant="caption" display="block" gutterBottom>
              {formatMessage({ ...messages.isRequired })}
            </Typography>
          ) : null}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label={formatMessage({ ...messages.labelDesc })}
            name="description"
            autoComplete="description"
            multiline
            {...fieldProps({
              field: 'description',
              customError: null,
            })}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm()
            setOpen(false)
          }}
          color="primary"
        >
          {formatMessage({ ...messages.cancel })}
        </Button>
        <Button
          onClick={async () => {
            onSave()
          }}
          color="primary"
          disabled={!hasFormChanged()}
        >
          {texts.button}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
