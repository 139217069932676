import React from 'react'
import { useQuery } from '@apollo/client'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import RestrictedPageBody from '../RestrictedPageBody'
import AddItem from '../AddItem'
import InviteUser from '../InviteUser'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import ItemsList from '../ItemsList'
// import TeamSearchResult from '../TeamSearchResult'
import Badge from '../Badge'
import TeamItems from '../TeamItems'
import TeamUsers from '../TeamUsers'
import TeamChat from '../TeamChat'
import TeamLogs from '../TeamLogs'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import Box from '@material-ui/core/Box'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import MBadge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../TabPanel'
import HomeIcon from '@material-ui/icons/Home'
import ChatIcon from '@material-ui/icons/Chat'
import GroupIcon from '@material-ui/icons/Group'
import ListAltIcon from '@material-ui/icons/ListAlt'
import HistoryIcon from '@material-ui/icons/History'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// import ImageIcon from '@material-ui/icons/Image'
import ItemDetails from '../ItemDetails'
import SearchItems from '../SearchItems'
import EditTeam from '../EditTeam'
import TEAM from '../../libs/gql/TEAM'
import storeAPI from '../../libs/gql/storeAPI'

import { useIntl } from 'react-intl'
import messages from './messages'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: 10,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 24,
    marginBottom: 15,
    display: 'flex',
    alighItems: 'center',
  },
  pos: {
    marginBottom: 12,
  },
  listToot: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  autoComplete: {
    margin: '10px 0',
  },
  tabs: {
    flexGrow: 1,
  },
  tab: {
    fontSize: 9,
    padding: 1,
    minWidth: 60,
  },
}))

const tabs = ['home', 'items', 'members', 'chat', 'logs']

function Team(props) {
  const {
    // auth: { user },
    app,
    match: {
      params: { teamId },
    },
    userMeta,
    doRefetch,
  } = props

  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { tab } = useParams()
  const { itemId, tab: itemDetailsTab } = useParams()
  const history = useHistory()

  const { loading, error, data, refetch } = useQuery(TEAM, {
    variables: {
      id: teamId,
    },
  })
  // const [search, setSearch] = React.useState(null)
  console.log('TABS', tab, tabs.indexOf(tab))
  const currentTab = tab && !itemId ? tabs.indexOf(tab) : 0

  const [anchorMenuElement, setAnchorMenuElement] = React.useState(null)

  if (doRefetch) {
    refetch()
  }

  if (loading) return <Loader loading />
  if (error)
    return (
      <RestrictedPageBody>
        <ErrorMessage error={error} />
      </RestrictedPageBody>
    )

  const { team } = data
  const { items = [] } = userMeta
  const userItems = items.filter(item => item.teamId === teamId)
  const userItemsIds = userItems.map(item => item.id)
  const chatHasMessages = (() => {
    return userMeta.chatChannels
      .filter(chatChannel => chatChannel.topic === 'ITEM' && chatChannel.item.teamId === teamId)
      .some(chatChannel => {
        return new Date(chatChannel.updatedAt).getTime() > (app.chat[`chat_item_${chatChannel.item.id}`] || 0)
      })
  })()
  // const teamInvitations = userMeta.teamInvitations.filter(teamInvitation => teamInvitation.team.id === teamId)

  console.log('chatHasMessages', chatHasMessages, app.chat, userMeta.chatChannels)
  // console.log('SEARCH', search)
  return (
    <RestrictedPageBody>
      <Menu
        anchorEl={anchorMenuElement}
        keepMounted
        open={Boolean(anchorMenuElement)}
        onClose={event => {
          setAnchorMenuElement(null)
        }}
      >
        <EditTeam team={team}>
          {editTeamProps => (
            <MenuItem
              onClose={event => {
                setAnchorMenuElement(null)
              }}
              onClick={() => {
                setAnchorMenuElement(null)
                editTeamProps.setOpen(true)
              }}
            >
              Edit Team
            </MenuItem>
          )}
        </EditTeam>
      </Menu>
      <Typography className={classes.title} color="textPrimary">
        <IconButton
          size="small"
          onClick={event => {
            setAnchorMenuElement(event.currentTarget)
          }}
        >
          <MoreVertIcon />
        </IconButton>
        {team.name}
      </Typography>
      <Paper className={classes.root}>
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => {
            const newTab = tabs[newValue] === undefined ? tabs[0] : tabs[newValue]
            console.log('TABS onClick', newValue, newTab, tabs)
            history.push(`/team/${teamId}/${newTab}`)
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab className={classes.tab} label={formatMessage({ ...messages.tabHome })} icon={<HomeIcon />} />
          <Tab className={classes.tab} label={formatMessage({ ...messages.tabItems })} icon={<ListAltIcon />} />
          <Tab className={classes.tab} label={formatMessage({ ...messages.tabMembers })} icon={<GroupIcon />} />
          <Tab
            className={classes.tab}
            label={formatMessage({ ...messages.tabChat })}
            icon={
              <MBadge color="secondary" variant="dot" invisible={!chatHasMessages}>
                <ChatIcon />
              </MBadge>
            }
          />
          <Tab className={classes.tab} label={formatMessage({ ...messages.tabLogs })} icon={<HistoryIcon />} />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={0}>
        <Card className={classes.root} style={{ marginBottom: 30 }}>
          <CardContent>
            <Box display="flex" style={{ fontSize: 14 }}>
              <Box style={{ marginRight: 10 }}>
                <span style={{ margin: '0 5px 5px 0', display: 'inline-block' }}>
                  <Badge content={team.numberOfMembers || 0} color="blue" hue="300" />{' '}
                  {formatMessage({ ...messages.numberOfMembers })}
                </span>
                <InviteUser team={{ ...team, id: teamId }} update={() => {}} />
              </Box>
              <Box flex="1 1 auto">
                <span style={{ margin: '0 5px 5px 0', display: 'inline-block' }}>
                  <Badge content={team.numberOfItems || 0} color="blue" hue="300" />{' '}
                  {formatMessage({ ...messages.numberOfItems })}
                </span>
                <AddItem
                  team={{ ...team, id: teamId }}
                  update={(store, { data: { addItem: newItem } }) => {
                    storeAPI.addItem({ store, data: { newItem, teamId, updateTeam: true, updateUserMeta: true } })
                  }}
                >
                  {addItemProps => (
                    <Button
                      size="small"
                      style={{ fontSize: 12 }}
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        addItemProps.setOpen(true)
                      }}
                    >
                      {formatMessage({ ...messages.addItem })}
                    </Button>
                  )}
                </AddItem>
              </Box>
            </Box>
            <SearchItems
              team={team}
              onClick={item => {
                if (item?.id) {
                  history.push(`/team/${team.id}/item/${item.id}`)
                }
              }}
            />
          </CardContent>
          <CardActions>{/* <Button size="small">Learn More</Button> */}</CardActions>
        </Card>

        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {formatMessage({ ...messages.inYourPossession })}
        </Typography>
        <ItemsList
          items={userItems}
          team={{ ...team, id: teamId }}
          userMeta={userMeta}
          update={(store, { data: { editItem: updatedItem } }) => {
            // const queryProps = { query: TEAM, variables: { id: teamId } }
            // const data = store.readQuery(queryProps)
            // store.writeQuery({
            //   ...queryProps,
            //   data: {
            //     ...data,
            //     team: {
            //       ...data.team,
            //       numberOfItems: data.team.numberOfItems + 1,
            //       items: data.team.items.map(item => {
            //         console.log(item.SK, updatedItem)
            //         if (item.id === updatedItem.id) {
            //           console.log('LALA', item, updatedItem, {
            //             ...item,
            //             ...updatedItem,
            //           })
            //           return {
            //             ...item,
            //             ...updatedItem,
            //           }
            //         }
            //         return item
            //       }),
            //     },
            //   },
            // })
          }}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <TeamItems userMeta={userMeta} update={() => {}} team={{ ...team, id: teamId }} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <TeamUsers team={{ ...team, id: teamId }} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <TeamChat app={app} userMeta={userMeta} team={{ ...team, id: teamId }} />
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        <TeamLogs team={{ ...team, id: teamId }} />
      </TabPanel>

      {itemId ? (
        <ItemDetails
          borrower={userItemsIds.includes(itemId)}
          team={team}
          item={{
            id: itemId,
          }}
          activeTab={itemDetailsTab}
          setClose={() => history.push(`/team/${team.id}`)}
        />
      ) : null}
    </RestrictedPageBody>
  )
}

const mapStateToProps = state => ({
  auth: state.auth || {},
  app: state.app || {},
})

const mapDispatchToProps = null
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Team)
