/* eslint-disable */
import React from 'react'
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography'
// import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Container from '@material-ui/core/Container'
// import Drawer from '@material-ui/core/Drawer'
import Footer from '../Footer'
import Header from '../Header'
import Grid from '@material-ui/core/Grid'
// import bg1 from '../bg1.jpg'

// const useStyles = makeStyles(theme => ({
//   root: {
//     textAlign: 'center',
//     fontSize: '2.5rem',
//     display: 'flex',
//     flexDirection: 'column',
//     minHeight: '10vh',
//   },
//   body: {
//     bold: { fontWeight: 'bold' },
//     fontSize: '1.5rem',
//     padding: '2% 10% 2% 10%',
//   },
// }))

function PresentationPage(props) {
  // const classes = useStyles()

  return (
    <React.Fragment>
      <Header />
      <div style={{ backgroundColor: '#FFF' }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <h1>Takina Project For Commons</h1>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non dictum sem, in efficitur tortor.
                Fusce semper convallis turpis, eget suscipit libero blandit ut. Vestibulum consectetur ex ac turpis
                egestas, sit amet ultrices lectus feugiat. Donec fringilla nisi scelerisque interdum pellentesque.
                Interdum et malesuada
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <img src="/images/1.png" alt="1" style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ backgroundColor: '#3F51B5' }}>
        <Container>
          <div style={{ color: '#FFF', padding: '20px 0 60px' }}>
            <h1>Takina Project For Commons</h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non dictum sem, in efficitur tortor. Fusce
            semper convallis turpis, eget suscipit libero blandit ut. Vestibulum consectetur ex ac turpis egestas, sit
            amet ultrices lectus feugiat. Donec fringilla nisi scelerisque interdum pellentesque. Interdum et malesuada
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: '#FFF', marginBottom: 20 }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <img src="/images/2.png" alt="2" style={{ width: '100%' }} />
            </Grid>
            <Grid item sm={6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <h1>Takina Project For Commons</h1>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non dictum sem, in efficitur tortor.
                Fusce semper convallis turpis, eget suscipit libero blandit ut. Vestibulum consectetur ex ac turpis
                egestas, sit amet ultrices lectus feugiat. Donec fringilla nisi scelerisque interdum pellentesque.
                Interdum et malesuada
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = null
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresentationPage)
