/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { IntlProvider } from 'react-intl'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'
// import history from './history';
import client from '../../libs/client'
import PrivateRoute from './PrivateRoute'
import Dashboard from '../Dashboard'
import Team from '../Team'
import Login from '../Login'
import Registration from '../Registration'
import Account from '../Account'
import UserActions from '../UserActions'
import PresentationPage from '../PresentationPage'
import auth from '../../libs/auth'
import { updateApp } from '../../libs/redux/reducers/app'
import pusherPushNotifications from '../../libs/beams'
import { messages } from '../../libs/i18n'

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import './style.css'

function App(props) {
  const {
    app: { feedback },
    auth: { user },
    setFeedback,
  } = props
  React.useEffect(() => {
    const beamsClient = pusherPushNotifications(user?.id)
    return () => {
      if (beamsClient) {
        beamsClient.stop().catch(console.error)
      }
    }
  }, [user])
  React.useEffect(() => {
    let timeout
    if (feedback && feedback.open) {
      timeout = setTimeout(() => {
        setFeedback({
          open: false,
          status: null,
          text: '',
        })
      }, 5000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [feedback, setFeedback])

  const language = navigator.language.split(/[-_]/)[0]
  // const language = 'el'
  return (
    <ApolloProvider client={client}>
      <IntlProvider locale={language} key={language} messages={messages[language]}>
        <Router>
          <Switch>
            <Route exact path="/" component={PresentationPage} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} {...props} />
            <PrivateRoute exact path="/my-actions" component={UserActions} {...props} />
            <PrivateRoute exact path="/my-account" component={Account} {...props} />
            <PrivateRoute exact path="/team/:teamId/item/:itemId/tab/:tab" component={Team} {...props} />
            <PrivateRoute exact path="/team/:teamId/item/:itemId" component={Team} {...props} />
            <PrivateRoute exact path="/team/:teamId" component={Team} {...props} />
            <PrivateRoute exact path="/team/:teamId/:tab" component={Team} {...props} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Registration} />
            <Route path="*">No page 404</Route>
          </Switch>
        </Router>

        {feedback && feedback.open ? (
          <Snackbar
            open
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity={feedback.status} elevation={6} variant="filled">
              {['success', 'error'].includes(feedback.status) ? feedback.text : ''}
            </Alert>
          </Snackbar>
        ) : null}
      </IntlProvider>
    </ApolloProvider>
  )
}

const mapStateToProps = state => ({
  auth: state.auth || {},
  app: state.app || {},
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => {
    auth.logout()
  },
  setFeedback: feedback => {
    dispatch(
      updateApp({
        feedback,
      }),
    )
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
